<!--Section contactPageWidget-->
<section class="contactPageWidget">
    <div class="row mx-0">
        <div class="col-md-12 col-xl-6 px-0 contact-page-iframe">
            <img style="pointer-events: none;"  src="assets/images/map.png" alt="Locate Us">
            <!-- <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.322214267066!2d-73.98935248471354!3d40.75493734292478!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258ff9722c287%3A0xed52a13cbfa86903!2s1441%20Broadway%20%235%2C%20New%20York%2C%20NY%2010018%2C%20USA!5e0!3m2!1sen!2sin!4v1623406563846!5m2!1sen!2sin"
                style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
        </div>
        <div class="col-md-12 col-xl-6">
            <div class="contactForm">
                <!-- <div class="col-md-6 col-xl-6 offset-md-3">
                    <div class="location mt-5 location-one">
                        <p>1441 Broadway,</p>
                        <p>#5, New York, NY 10018</p>
                        <p><a href="mailto:hello@actionfi.com">hello@actionfi.com </a></p>
                        <p><a href="tel:(646) 290-6311">+1 (646) 290-6311</a></p>
                    </div>
                </div>
                <hr> -->
                <!-- <div class="col-md-6 col-xl-6">
                </div> -->
               
                <div class="col-md-12 col-xl-12 adjust-bottom-margin">
                <div class="row adjust-padding">
                    <div class="col-md-12 col-xl-12">
                        <div class="location mt-5 location-one">
                            <p>1441 Broadway, #5,<br>
                            New York, NY 10018</p>
                            <div class="mail_wrap">
                                <p> <img style="pointer-events: none;"  src="assets/images/icons/home/ic_email.svg" alt="mail icon"><a href="mailto:hello@actionfi.com">hello@actionfi.com </a></p>
                                <p><img style="pointer-events: none;"  src="assets/images/icons/home/ic_land_1.svg" alt="mail icon"><a href="tel:1 646 290 6311">+1 646 290 6311</a></p>
                                
                        </div>    
                        </div>
                        <hr />
                    </div>
                    
                </div>
                <div class="row adjust-padding">
                    <div class="col-md-6 col-xl-6">
                        <div class="location location-one">
                            <p>MEDA Building, Kakkanad,<br> 
                                Ernakulam, Kerala, India - 682037</p>
                                <div class="mail_wrap">
                                    <p> <img style="pointer-events: none;"  src="assets/images/icons/home/ic_email.svg" alt="mail icon"><a href="mailto:hello@actionfi.com">hello@actionfi.com </a></p>
                            <p><img style="pointer-events: none;"  src="assets/images/icons/home/ic_land_1.svg" alt="mail icon"><a href="tel: 91 484 2993399"> +91 484 299 3399</a></p>
                           </div>


                        </div>
                    </div>
                <!-- </div> -->
                <!-- <div class="row"> -->
                    <div class="col-md-6 col-xl-6">
                        <div class="location location-one">
                            <p>C5 UL Cyber Park, Special Economic Zone,<br>
                            Calicut, Kerala, India - 673016</p>
                            <div class="mail_wrap">
                                <p> <img style="pointer-events: none;"  src="assets/images/icons/home/ic_email.svg" alt="mail icon"><a href="mailto:hello@actionfi.com">hello@actionfi.com </a></p>
                            <p><img style="pointer-events: none;"  src="assets/images/icons/home/ic_land_1.svg" alt="mail icon"><a href="tel:91 495 2351100"> +91 495 235 1100</a></p>
                       </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
            <hr/>
            <!-- <hr> -->
            <div class="location mt-5">
                <h5>Get in Touch</h5>
            </div>
                
                <form [formGroup]="contactForm">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" autocomplete="off" formControlName="full_name" [ngClass]="{'has_error' : isFormSubmitted && formControls.full_name.errors}" name="full_name" class="form-control" placeholder="Enter Your name">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Email</label>
                                <input type="email" autocomplete="off" formControlName="email" [ngClass]="{'has_error' : isFormSubmitted && formControls.email.errors}" name="email" class="form-control" placeholder="Enter your email">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group ">
                                <label>Message</label>
                                <input type="text" autocomplete="off" formControlName="message" [ngClass]="{'has_error' : isFormSubmitted && formControls.message.errors}" name="message" class="form-control" placeholder="Enter your message">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <ngx-recaptcha2 #captchaElem siteKey="{{recaptchaKey}}" (success)="handleSuccess($event)" [size]="size" [hl]="lang" [theme]="theme" [type]="type" formControlName="recaptcha"></ngx-recaptcha2>
                            </div>
                        </div>
                    </div>
                    <div class="text-right contact-submit">
                        <span class="alert in success-green" *ngIf="success_msg">
                            {{success_msg}}
                        </span>
                        <span class="alert in error-red" *ngIf="error_msg">
                            {{error_msg}}
                        </span>
                        <button type="submit" class="btn btn-primary rounded-0" (click)="contactFormSubmit()">Send Now</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</section>
<!--Section contactPageWidget End-->