import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SanitizedHtmlPipe } from './../sanitized-html.pipe';
import { NgxSmartModalModule,NgxSmartModalService } from 'ngx-smart-modal';
import {InsideViewportDirective} from './inside-viewport.directive';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { EnterpriseComponent } from './enterprise/enterprise.component';
import { AboutComponent } from './about/about.component';
import { AutomationLibraryComponent } from './automation-library/automation-library.component';
import { CoeComponent } from './coe/coe.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { IntegrationServicesComponent } from './integration-services/integration-services.component';
import { AutomationScenarioComponent } from './automation-scenario/automation-scenario.component';
import { AutomationScenarioDetailsComponent } from './automation-scenario-details/automation-scenario-details.component';
import { StreamingAiComponent } from './streaming-ai/streaming-ai.component';
import { CpoComponent } from './cpo/cpo.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { CareerComponent } from './career/career.component';
import { AutomationScenarioSapComponent } from './automation-scenario-sap/automation-scenario-sap.component';
import { RpageComponent } from './rpage/rpage.component';
import { HyperautomationAsAServiceComponent } from './hyperautomation-as-a-service/hyperautomation-as-a-service.component';
import { HyperautomationInSapComponent } from './hyperautomation-in-sap/hyperautomation-in-sap.component';
import { PartnersComponent } from './about/partners/partners.component';
import { EndusersComponent } from './about/endusers/endusers.component';
import { AwardsComponent } from './about/awards/awards.component';
import { CounterDisplayComponent } from './counter-display/counter-display.component';

export const MY_NATIVE_FORMATS = {
  fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'},
  datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
  timePickerInput: {hour: 'numeric', minute: 'numeric'},
  monthYearLabel: {year: 'numeric', month: 'short'},
  dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
  monthYearA11yLabel: {year: 'numeric', month: 'long'},
};

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    EnterpriseComponent,
    AboutComponent,
    AutomationLibraryComponent,
    CoeComponent,
    ContactUsComponent,
    IntegrationServicesComponent,
    AutomationScenarioComponent,
    AutomationScenarioDetailsComponent,
    StreamingAiComponent,
    CpoComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    SanitizedHtmlPipe,
    PagenotfoundComponent,
    CareerComponent,
    AutomationScenarioSapComponent,
    RpageComponent,
    HyperautomationAsAServiceComponent,
    HyperautomationInSapComponent,
    InsideViewportDirective,
    PartnersComponent,
    EndusersComponent,
    AwardsComponent,
    CounterDisplayComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxCaptchaModule,
    NgxSmartModalModule.forRoot()
  ],
  providers: [{provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS},NgxSmartModalService],
  bootstrap: [AppComponent]
})
export class AppModule { }

// {provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS}
