import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service'
import { Title, Meta } from '@angular/platform-browser'
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-integration-services',
  templateUrl: './integration-services.component.html',
  styleUrls: ['./integration-services.component.scss']
})
export class IntegrationServicesComponent implements OnInit {

  constructor(private commonService: CommonService, private title: Title, private meta: Meta, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.title.setTitle("BPO & Business Consulting | Sustainable Automation for transformation projects | actionfi");
    this.meta.updateTag({
      name: 'description',
      content: 'RUNMYBOT is sustainable automation in BPO Model, will help to reduce Implementation timeline & avoid cost overruns'
    });
    this.route.queryParams.subscribe(params => {
      if (params.demo == "y") {
        setTimeout(()=>{
          this.toggleModal();
        },100);
      }
    });
  }

  toggleModal() {
    this.commonService.showGetStartedModal("toggle");
  }

}
