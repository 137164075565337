import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  // Observable string sources
  private getStartedModalSource = new Subject<string>();

  // Observable string streams
  getStartedModal$ = this.getStartedModalSource.asObservable();

  // Service message commands
  showGetStartedModal(toggle: string) {
    this.getStartedModalSource.next(toggle);
  }

}
