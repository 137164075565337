
    <app-header></app-header>
    <router-outlet></router-outlet>

    <app-footer></app-footer>


    <!-- Get Started Modal (Schedule a Demo)-->
    <div class="modal fade getStarted-modal-lg" [ngClass]="{'show': showModal === true}" id="getStartedModal"
        tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xlg">
            <div class="modal-content">
                <div class="row mx-0">
                    <div class="col-md-12 col-lg-7 px-0">
                        <div class="modalBg">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                (click)="toggleModal()">
                                <img style="pointer-events: none;"  src="assets/images/close.svg" alt="close">
                            </button>
                            <div class="footerContent">
                                <h2>Lets do more Together</h2>
                                <p>The easiest & cost effective way to build a <br>no-code bot for your business</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-5">
                        <div class="registrationForm">
                            <div class="actionfiLogo">
                                <img style="pointer-events: none;"  src="assets/images/logo.png" width="145px" height="45px" alt="logo">
                            </div>
                            <form [formGroup]="demoForm">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" autocomplete="off" formControlName="full_name" name="full_name" [ngClass]="{'has_error' : isFormSubmitted && formControls.full_name.errors}" class="form-control" placeholder="Enter your name">
                                </div>
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" autocomplete="off" formControlName="email" name="email" [ngClass]="{'has_error' : isFormSubmitted && formControls.email.errors}" class="form-control" placeholder="Enter your email">
                                </div>
                                <div class="form-group">
                                    <label [owlDateTimeTrigger]="dt" class="cursor-pointer">Let’s Talk</label>
                                    <input [min]="min_datetime" [owlDateTimeTrigger]="dt" autocomplete="off" formControlName="date_time" [owlDateTime]="dt" [ngClass]="{'has_error' : isFormSubmitted && formControls.date_time.errors}" class="form-control date_time_picker" placeholder="Date & Time">
                                    <owl-date-time [startAt]="default_datetime" #dt></owl-date-time>
                                </div>
                                <div class="form-group">
                                    <label>Message</label>
                                    <input type="text" autocomplete="off" formControlName="message" name="message" class="form-control" placeholder="Enter your message">
                                </div>
                                <div class="form-group">
                                    <ngx-recaptcha2 #captchaElemPopup siteKey="{{recaptchaKey}}" (success)="handleSuccess($event)" [size]="size" [hl]="lang" [theme]="theme" [type]="type" formControlName="recaptcha"></ngx-recaptcha2>
                                </div>
                                <div class="text-right">
                                    <span class="alert in success-green" *ngIf="success_msg">
                                        {{success_msg}}
                                    </span>
                                    <span class="alert in error-red" *ngIf="error_msg">
                                        {{error_msg}}
                                    </span>
                                    <button type="submit" class="btn btn-primary rounded-0" (click)="demoFormSubmit()">Send Now</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>