import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyAboutService {

  constructor(private http: HttpClient) { }

  /**
   * LOAD PARTNER LIST
   */
  load_partner_list(query: any) {
    return this.http.get<any>(`${environment.apiURL}/api/partners`,{ params: query });
  }

  /**
   * LOAD ENDUSER LIST
   */
  load_enduser_list(query: any) {
    return this.http.get<any>(`${environment.apiURL}/api/end-users`,{ params: query });
  }

  /**
   * LOAD AWARD LIST
   */
  load_award_list(query: any) {
    return this.http.get<any>(`${environment.apiURL}/api/awards`,{ params: query });
  }


}
