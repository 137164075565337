import { Component, OnInit,OnDestroy } from '@angular/core';
import { CommonService } from '../services/common.service'
import { ApiAutomationScenarioService } from '../services/api-automation-scenario.service'
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.scss']
})
export class EnterpriseComponent implements  OnInit, OnDestroy {

  enterpriseSubscribe: any;
  enterprises: any = [];
  apiURL : any = environment.apiURL

  constructor(private commonService: CommonService, private apiAutomationScenarioService:ApiAutomationScenarioService, private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(`Enterprise Application | Business specific built-in integrations to ERP applications & systems | actionfi`);
    this.meta.updateTag({ 
      name: 'description',
      content: 'Connect RUNMYBOT with ERP applications like SAP, Oracle, NetSuite, Microsoft Dynamics, Google Cloud, Salesforce, Service Now, HL7 etc.'
    });
    this.loadEnterpriseList();
  }

  /**
   * Toggle Modal
   */
  toggleModal() {
    this.commonService.showGetStartedModal("toggle");
  }

  /**
   * Load Enterprise List
   */
  loadEnterpriseList(){
    this.enterpriseSubscribe = this.apiAutomationScenarioService.load_enterprise_list().subscribe((data:any)=>{
      if (data.success) {
        this.enterprises = data.enterprise_list;
      } else {
        console.log(data.msg);
      }
    }, (error: any) => {
      console.log(error);
    });
  }

  /**
   * Destroy
   */
  ngOnDestroy(){
    if (this.enterpriseSubscribe) this.enterpriseSubscribe.unsubscribe();
  }

}
