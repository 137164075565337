import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiAutomationScenarioService {

  constructor(private http: HttpClient) { }

  /**
   * LOAD ENTERPRISE LIST
   */
  load_enterprise_list() {
    return this.http.get<any>(`${environment.apiURL}/api/enterprise-list`);
  }

  /**
   * LOAD AUTOMATION LIST
   */
  load_automation_list(query: any) {
    return this.http.get<any>(`${environment.apiURL}/api/automation-list`, { params: query });
  }

  /**
   * LOAD FILTER LIST
   */
  load_filter_list(query: any) {
    return this.http.get<any>(`${environment.apiURL}/api/filter-list`, { params: query });
  }

  /**
   * LOAD AUTOMATION DETAILS
   */
  load_automation_details(query: any) {
    return this.http.get<any>(`${environment.apiURL}/api/automation-details`, { params: query });
  }

  /**
   * SAVE DEMO FORM DATA
   */
   save_demo_schedule(data: any) {
    return this.http.post<any>(`${environment.apiURL}/api/schedule-demo-save`, data);
  }

  /**
   * SAVE CONTACT FORM DATA
   */
   save_contact_request(data: any) {
    return this.http.post<any>(`${environment.apiURL}/api/contact-form-save`, data);
  }

}
