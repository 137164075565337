import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service'
import { ApiAutomationScenarioService } from '../services/api-automation-scenario.service'
import { ActivatedRoute } from "@angular/router";
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { runmybot_title_registered_html_replace } from '../runmybot-replacer';
import { runmybot_registered_html_replace } from '../runmybot-replacer';
import { runmybot_registered_unicode_replace } from '../runmybot-replacer';

@Component({
  selector: 'app-automation-scenario-details',
  templateUrl: './automation-scenario-details.component.html',
  styleUrls: ['./automation-scenario-details.component.scss']
})
export class AutomationScenarioDetailsComponent implements OnInit {
  runmybot_title_registered_html_replace=runmybot_title_registered_html_replace;
  runmybot_registered_html_replace=runmybot_registered_html_replace;
  runmybot_registered_unicode_replace=runmybot_registered_unicode_replace;
  
  automationSubscribe: any;
  automationScenarios: any = [];
  automation_scenario_services: any = [];
  erp_slug: any = "";
  automation_slug: any = "";
  query: any = {};
  apiURL: any = environment.apiURL;
  shortDescription: any;

  constructor(private commonService: CommonService, private apiAutomationScenarioService: ApiAutomationScenarioService, private route: ActivatedRoute, private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.query = {};
    this.erp_slug = this.route.snapshot.params.slug;
    this.automation_slug = this.route.snapshot.params.automation_slug;
    this.loadAutomationScenarioDetails();
    localStorage.setItem('page_session','y');
    this.route.queryParams.subscribe(params => {
      if (params.demo == "y") {
        setTimeout(()=>{
          this.toggleModal();
        },100);
      }
    });
  }

  toggleModal() {
    this.commonService.showGetStartedModal("toggle");
  }

  /**
   * Load Automation Details
   */
  loadAutomationScenarioDetails() {
    this.query['erp_slug'] = this.erp_slug;
    this.query['automation_slug'] = this.automation_slug;
    this.automationSubscribe = this.apiAutomationScenarioService.load_automation_details(this.query).subscribe((data: any) => {
      if (data.success) {
        this.automationScenarios = data.automation_scenario_details;
        this.automation_scenario_services = data.automation_scenario_services;
        this.shortDescription = data.short_description;
        this.title.setTitle(`${runmybot_registered_unicode_replace(data.meta_title)}`);
        this.meta.updateTag({
          name: 'description',
          content: data.meta_description
        });
        this.meta.updateTag({
          name: 'title',
          content: data.meta_title
        });
        // this.title.setTitle(`Enterprise | ${data.erp_title} | ${data.automation_title}`);
      } else {
        console.log(data.msg);
      }
    }, (error: any) => {
      console.log(error);
    });
  }

  /**
   * Check
   */
   isExist(services:any, id:any){
     id = id+'';
     if (services.indexOf(id)>=0){
       return true;
     }else{
       return false;
     }
   }



}
