<!--Section Banner-->
<section class="streamingAiBannerWidget">
    <div class="container">
        <div class="row ">
            <div class="col-md-12 col-lg-10 mb-5">
                <h1>AI-Powered Stream Processing to Manage Your IoT Data Needs</h1>
                <p>
                    <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> is a hybrid platform that has built-in stream processing for your IoT data. The AI-powered platform can process your data in motion from IoT devices to standard systems and IT workloads asynchronously to complete a business process integration.
                </p>
                <button type="button" class="btn btn-primary rounded-0" (click)="toggleModal()">Schedule a Demo</button>
            </div>
        </div>
    </div>
</section>
<!--Section Banner-->

<!-- IoT Data collector -->
<section class="blockListing">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6">
                <img style="pointer-events: none;"  src="assets/images/icons/ai/iot_data_collecting.svg" alt="IoT Data collector">
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="row mx-0 justify-content-between">
                    <h4>IoT Data collector</h4>
                </div>
                <p class="remove-margin-line-height">
                    <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> has built-in data collectors for different IoT devices, including store-and-forward types and low-power protocols. The collector algorithms are designed to understand data drift and automatic learning to address quality issues.
                </p>
            </div>
        </div>
    </div>
</section>
<!-- IoT Data collector -->

<!-- Event Stream Processing -->
<section class="blockListing lightGray">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6">
                <div class="row mx-0 justify-content-between">
                    <h4>Event Stream Processing</h4>
                </div>
                <p class="remove-margin-line-height">
                    <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> is designed to support varying data volumes and ingestion rates. <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> can parse, enrich and apply complex transformations to create data pipelines that can be easily consumed by business applications.
                </p>
            </div>
            <div class="col-md-12 col-lg-6 text-right">
                <img style="pointer-events: none;"  src="assets/images/icons/ai/event_stream_processing.svg" alt="Event Stream Processing">
            </div>
        </div>
    </div>
</section>
<!-- Event Stream Processing -->
<!-- Edge to Cloud Management -->
<section class="blockListing">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6">
                <img style="pointer-events: none;"  src="assets/images/icons/ai/edge_to_cloud_management.svg" alt="Edge to Cloud Management">
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="row mx-0 justify-content-between">
                    <h4>Edge to Cloud Management</h4>
                </div>
                <p class="remove-margin-line-height">
                    Our AI-driven platform can detect anomalous behaviors and react timely to operational events. This fully managed platform and its analytics capabilities address the lineage and governance of streaming data from end to end.
                </p>
            </div>
            <div class="col-md-12 text-right">
                <a [routerLink]="['/automation-library']" [queryParams]="{query: 12}" class="btn btn-primary rounded-0 btn-read-more">
                    <span class="mr-3">Read more</span>
                    <svg width="24" height="28" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M16 31V31C7.715 31 1 24.285 1 16V16C1 7.715 7.715 1 16 1V1C24.285 1 31 7.715 31 16V16C31 24.285 24.285 31 16 31Z"
                            stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M22.6663 15.9998H9.33301" stroke="#fff" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M17.667 11L22.667 16L17.667 21" stroke="#fff" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- Edge to Cloud Management -->