import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { CompanyAboutService } from 'src/app/services/company-about.service';

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.scss']
})
export class AwardsComponent implements OnInit,OnDestroy {

  apiURL: any = environment.apiURL;
  items: any = [];
  itemsSubscribe: any;
  query: any = {};
  page: number = 1;
  has_next: number = 0;
  ids: any = [];
  isDataLoaded: string = 'no';

  constructor(private companyAboutService: CompanyAboutService, private route: ActivatedRoute, private router: Router) {
    if(!localStorage.getItem('page_session')){
      localStorage.setItem('page_session','d');
    }
    router.events.subscribe((event:any) => {
      if (event instanceof NavigationEnd) {
        if(localStorage.getItem('page_session')=="n"){
          localStorage.setItem('award_page','1');
        }
      }
    });
   }

  ngOnInit(): void {
    this.query = {};
    localStorage.setItem('award_page','1');
    this.loadItemList();
  }

  ngOnDestroy() {
    if (this.itemsSubscribe) this.itemsSubscribe.unsubscribe();
  }

  loadItemList() {
    var pg = localStorage.getItem('award_page');
    if(pg && pg!=null){
      this.page = parseInt(pg);
    }
    this.query['page'] = this.page;
    this.query['page_session'] = localStorage.getItem('page_session');
    this.itemsSubscribe = this.companyAboutService.load_award_list(this.query).subscribe((data: any) => {
      if (data.success) {
        this.ids = new Set(this.items.map((d:any) => d.id));
        this.items = [...this.items, ...data.awards.filter((d:any) => !this.ids.has(d.id))];

        this.page = data.page;
        this.has_next = data.has_next;
        setTimeout(()=>{
          this.isDataLoaded = 'yes';
          // localStorage.setItem('page_session','n');
          if(this.page==1){
            localStorage.setItem('award_inital_item_count',this.items.length.toString());
          }
        },200);
      } else {
        console.log(data.msg);
        setTimeout(()=>{
          this.isDataLoaded = 'yes';
        },200);
      }
    }, (error: any) => {
      console.log(error);
      setTimeout(()=>{
        this.isDataLoaded = 'yes';
      },200);
    });
  }

  loadMore() {
    if (this.has_next) {
      this.page++;
      localStorage.setItem('award_page',this.page.toString());
      this.loadItemList();
    }
  }

  showLess(){
    this.page=1;
    localStorage.setItem('award_page',this.page.toString());
    var inital_item_count = localStorage.getItem('award_inital_item_count');
    if(inital_item_count && inital_item_count!=null){
      this.items= this.items.slice(0, parseInt(inital_item_count));
    }
    this.has_next=1;
    var titleDiv = document.getElementById("awards");
    titleDiv?.scrollIntoView({ behavior:'smooth',});
  }
}
