import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hyperautomation-in-sap',
  templateUrl: './hyperautomation-in-sap.component.html',
  styleUrls: ['./hyperautomation-in-sap.component.scss']
})
export class HyperautomationInSapComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
