<header>
  <div class="container">
    <div class="row align-items-center justify-content-between navbar_wrap">
      <div class="col-md-auto" style="padding-right: 0;">
        <a [routerLink]="['/']" class="logo">
          <img style="pointer-events: none;"  src="assets/images/logo.png" class="primary-logo" alt="actionfi">
        </a>
        <a href="https://store.sap.com/dcp/en/product/display-0000061023_live_v1/runmybot-for-sap" target="_blank" class="logo container-secondary-logo">
          <img style="pointer-events: none;"  src="assets/images/logo_header.png" class="normal-logo secondary-logo" alt="SAP Silver Partner">
        </a>
      </div>
      <div class="col-md-auto text-right" style="padding-left: 0;">
        <nav class="navbar navbar-expand-lg header_navigation">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <img style="pointer-events: none;"  src="assets/images/menu.svg" alt="menu">
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item dropdown" routerLinkActive="current">
                <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Solutions
                </a>
                <div class="dropdown-menu"
                  aria-labelledby="navbarDropdown">
                  <ul class="sub-menu">
                    <li>
                      <a [routerLink]="['/chief-product-officer']" routerLinkActive="current"><img style="pointer-events: none;" 
                          class="mr-2" src="assets/images/product-officer.svg" alt="">Chief Product
                        Officer</a>
                    </li>
                    <li>
                      <a [routerLink]="['/automation-coe']" routerLinkActive="current"><img style="pointer-events: none;" 
                          class="mr-2" src="assets/images/automation.svg" alt="">Automation COE</a>
                    </li>
                    <li>
                      <a [routerLink]="['/bpo-business-consulting']"
                        routerLinkActive="current"><img style="pointer-events: none;"  class="mr-2" src="assets/images/integration.svg"
                          alt="">BPO & Business Consulting
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item dropdown" routerLinkActive="current">
                <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Platforms
                </a>
                <div class="dropdown-menu"
                  aria-labelledby="navbarDropdown">
                  <ul class="sub-menu">
                    <li>
                      <a [routerLink]="['/automation-library']"
                        routerLinkActive="current"><img style="pointer-events: none;"  class="mr-2" src="assets/images/library.svg"
                          alt="">Automation Library</a>
                    </li>
                    <li>
                      <a [routerLink]="['/sap']" routerLinkActive="current">
                        <img style="pointer-events: none;"  class="mr-2" src="assets/images/menu_sap.svg" alt="">
                        <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> For SAP
                        <!-- <img style="pointer-events: none;"  class="mr-2" src="assets/images/menu_runmybot.png" alt=""> -->
                      </a>
                    </li>
                    <li>
                      <a [routerLink]="['/enterprise-application']"
                        routerLinkActive="current"><img style="pointer-events: none;"  class="mr-2" src="assets/images/enterprise.svg"
                          alt="">Enterprise Application</a>
                    </li>
                    <li>
                      <a [routerLink]="['/ai-streaming']"
                        routerLinkActive="current"><img style="pointer-events: none;"  class="mr-2" src="assets/images/streaming.svg"
                          alt="">AI Streaming</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/company']" routerLinkActive="current">Company</a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" href="http://demo.runmybot.com">Login</a>
              </li> -->
            </ul>
          </div>
        </nav>
        <button type="button" class="btn btn-link mr-3 rounded-login" onclick="location.href='http://demo.runmybot.com/';">Login</button>
        <button type="button" class="btn btn-primary rounded-0 sd" (click)="toggleModal()">Schedule a Demo</button>
      </div>
    </div>
  </div>
</header>
