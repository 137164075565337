import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'actionfi';
  constructor(public router: Router){  
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        gtag('config', environment.googleAnalyticsKey, {'page_path': event.urlAfterRedirects});
      }
    })
  }
}
