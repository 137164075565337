// import { Component, OnInit } from '@angular/core';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
@Component({
  selector: 'app-counter-display',
  templateUrl: './counter-display.component.html',
  styleUrls: ['./counter-display.component.scss']
})
export class CounterDisplayComponent implements OnInit,AfterViewInit {

  items = [
    { iconName: 'counter0.png', endValue: 180, currentValue: 0, label: 'Worldwide Customers' ,format: 'default'},
    { iconName: 'counter1.png', endValue: 50, currentValue: 0, label: 'Industries served ',format: 'default' },
    { iconName: 'counter2.png', endValue: 22, currentValue: 0, label: 'ERP systems integrated' ,format: 'default'},
    { iconName: 'counter3.png', endValue: 3000000, currentValue: 0, label: 'Transactions processed every month',format: 'million' }
  ];
  @ViewChild('counterContainer', { static: true }) counterContainer!: ElementRef;


  ngOnInit() {
    // Initialize the values if necessary
  }

  ngAfterViewInit() {
    this.createObserver();
  }

  createObserver() {
    const observerOptions = {
      root: null, // Use the viewport as the root
      threshold: 0.1 // Trigger when 10% of the element is visible
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.startCounting();
          observer.unobserve(entry.target); // Stop observing once the animation starts
        }
      });
    }, observerOptions);

    observer.observe(this.counterContainer.nativeElement);
  }

  startCounting() {
    this.items.forEach(item => this.animateValue(item, 6000));
  }

  formatNumber(value: number): string {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + ' Million';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'k';
    } else {
      return value.toString();
    }
  }

  animateValue(item: any, duration: number): void {
    const startValue = 0;
    const startTime = performance.now();
    const endValue = item.endValue;
    const frame = () => {
      const currentTime = performance.now();
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);
      const currentValue = Math.floor(progress * (endValue - startValue) + startValue);
      item.currentValue = this.formatNumber(currentValue);

      if (timeElapsed < duration) {
        requestAnimationFrame(frame);
      }
    };
    frame();
  }

  // ngOnInit() {
  //   this.items.forEach(item => this.animateValue(item, 5000));
  // }

  // animateValue(item: any, duration: number) {
  //   const startValue = 0;
  //   const startTime = performance.now();
  //   const endValue = item.endValue;
  //   const frame = () => {
  //     const currentTime = performance.now();
  //     const timeElapsed = currentTime - startTime;
  //     const progress = Math.min(timeElapsed / duration, 1);
  //     item.currentValue = Math.floor(progress * (endValue - startValue) + startValue);
  //     if (timeElapsed < duration) {
  //       requestAnimationFrame(frame);
  //     }
  //   };
  //   frame();
  // }
}
