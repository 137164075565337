import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service'
import { Title, Meta } from '@angular/platform-browser';
import { NgxSmartModalService } from 'ngx-smart-modal';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  usp1DescriptionShow:boolean=false;
  usp2DescriptionShow:boolean=false;
  usp3DescriptionShow:boolean=false;
  uspDescriptionLock:boolean=false;

  constructor(private commonService: CommonService, private title:Title, private meta: Meta,public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit(): void {
    this.title.setTitle("actionfi | Automation as a service with RUNMYBOT");
    this.meta.updateTag({ 
      name: 'description',
      content: 'Integration Platform for Connecting Enterprise Applications. Maximize your business potential with the pioneers in automated Bot solutions.'
    });
    if(window.innerWidth<992){
      this.uspDescriptionLock=true;
      this.usp1DescriptionShow=true;
      this.usp2DescriptionShow=true;
      this.usp3DescriptionShow=true;
    }else{
      this.uspDescriptionLock=false;
    }
  }

  toggleModal() {
    this.commonService.showGetStartedModal("toggle");
  }
  toggleWeb(){
    const externalUrl = 'https://exp.runmybot.com/accounts/login/';
    window.location.href = externalUrl;
    // window.open('https://exp.runmybot.com/accounts/login/')
    // this.router.navigateByUrl(externalUrl);

  }
}
