import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss']
})
export class PagenotfoundComponent implements OnInit {

  constructor(private title: Title, private route: Router) { }

  ngOnInit(): void {
    this.title.setTitle("404");
  }

  backHome(){
    this.route.navigate(['/']);
  }

}
