
<div  #counterContainer class="counter-container">
    <div class="container counter-container">
        <div class="counter-data">
            <div class="counter-item" *ngFor="let item of items">
            
                <div class="icon">
                    <img src="assets/images/countericons/{{ item.iconName }}" alt="{{ item.label }} icon">
                  </div>
                <div class="number">{{ item.currentValue }}+</div>
                <div class="label">{{ item.label }}</div>
              </div>
        </div>
        
      </div>
</div>  