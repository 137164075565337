<!--Section Banner-->
<section class="careerWidget">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-12 mb-5 container-diff">
                <h1 class="adjust-position">Careers</h1>
                <p>
                    We're looking for passionate people who desire to accept challenges, <br>build rapport with teams easily, and tell us what to do.
                </p>
            </div>
        </div>
    </div>
</section>

<section class="blockListing careerBlockListing" *ngIf="!careers.length">
    <div class="container">
        <div class="row align-items-center lightGray">
            <div class="col-md-12 col-lg-12 career-main-block text-center">
                <p>No Records Found!</p>
            </div>
        </div>
    </div>
</section>

<section class="blockListing careerBlockListing" *ngFor="let career of careers">
    <div class="container">
        <div class="row align-items-center lightGray">
            <div class="col-md-12 col-lg-4">
                <img style="pointer-events: none;"  [src]="career.logo" alt="{{career.title}}">
            </div>
            <div class="col-md-12 col-lg-8 career-main-block">
                <div class="row mx-0 justify-content-between">
                    <h4>{{career.title}}</h4>
                </div>
                <p [innerHTML]="career.description"></p>
                <button type="button" class="btn btn-primary career-rounded-0 career-read-less" (click)="toggle(career.id)" *ngIf="career.id!=toggleItem">Read More</button>
            </div>
        </div>
        <ng-template [ngIf]="career.id==toggleItem">
            <div class="row align-items-center lightGray" *ngFor="let row of career.rows">
                <div class="col-md-12 col-lg-4">&nbsp;</div>
                <div class="col-md-12 col-lg-8 career-main-block">
                    <div class="row mx-0 justify-content-between">
                        <h5>{{row.title}}</h5>
                    </div>
                    <p [innerHTML]="row.description"></p>
                </div>
            </div>
        </ng-template>
        <div class="row align-items-center lightGray" *ngIf="career.id==toggleItem">
            <div class="col-md-12 col-lg-4">&nbsp;</div>
            <div class="col-md-12 col-lg-8 career-main-block">
                <button type="button" class="btn btn-primary career-rounded-0 career-read-less" (click)="toggle(career.id)" *ngIf="career.id==toggleItem">Read Less</button>
            </div>
        </div>
    </div>
</section>
<section class="blockListing page-load-more">
    <div class="container">
        <div class="col-12 text-right mt-3">
            <a class="cercle_arrow" *ngIf="has_next" (click)="loadMore()">
                <span class="mr-3">Load more</span>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M16 31V31C7.715 31 1 24.285 1 16V16C1 7.715 7.715 1 16 1V1C24.285 1 31 7.715 31 16V16C31 24.285 24.285 31 16 31Z"
                        stroke="#7398C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M22.6663 15.9998H9.33301" stroke="#7398C7" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M17.667 11L22.667 16L17.667 21" stroke="#7398C7" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </a>
        </div>
    </div>
</section>