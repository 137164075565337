

<!--Section Banner-->
<section class="companyWidget">
    <div class="container">
        <div class="row" >
            <div class="col-md-12 col-lg-12 container-diff twoline">
                <h1 class="adjust-position">Elevate your Business <br>through Seamless Automation and Integration</h1>
            </div>
            <div class="col-md-12 col-lg-12 container-diff threeline">
                <h1 class="adjust-position">Elevate your Business <br>through Seamless Automation <br> and Integration</h1>
            </div>
        </div>
    </div>
</section>

<!-- Virtual Automation COE -->
<section class="blockListing">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6">
                <img style="pointer-events: none;"  src="assets/images/icons/company/virtual_automation_coe.svg" alt="Virtual Automation COE" oncontextmenu="return false;">
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="row mx-0 justify-content-between">
                    <h4>Virtual Automation COE</h4>
                </div>
                <p class="remove-margin-line-height">
                    End-to-end integration and automation is our sole focus, and we provide it at predictable cost. With our expertise and as your organization's virtual Automation Center of excellence, actionfi helps you cross that final mile of automation.
                </p>
            </div>
        </div>
    </div>
</section>
<!-- Virtual Automation COE -->

<!-- Full-Life-Cycle Support -->
<section class="blockListing lightGray">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6">
                <div class="row mx-0 justify-content-between">
                    <h4>Full-Life-Cycle Support</h4>
                </div>
                <p class="remove-margin-line-height">
                    We provide full-life-cycle support. Our integration team continues to update all our integration points, ERP versions and APIs. We also believe in providing full-life-cycle support to all our customers for our standard subscription fee.
                </p>
            </div>
            <div class="col-md-12 col-lg-6 text-right">
                <img style="pointer-events: none;"  src="assets/images/icons/company/full_life_cycle_support.svg" alt="Full-Life-Cycle Support" oncontextmenu="return false;">
            </div>

        </div>
    </div>
</section>
<!-- Full-Life-Cycle Support -->

<!-- Early Error Indicator -->
<section class="blockListing">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6">
                <img style="pointer-events: none;"  src="assets/images/icons/company/proactive_against_errors.svg" alt="Early Error Indicator" oncontextmenu="return false;">
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="row mx-0 justify-content-between">
                    <h4>Early Error Indicator</h4>
                </div>
                <p class="remove-margin-line-height">
                    We know about any issues before your users experience it. Our proactive, AI-led monitoring and pattern recognition system keeps an eye on all integration points and automation as part of our fully managed offering. Should any error or failure arise, it'll appear on our radar before your users experience it and by which time we could already be working its resolution.
                </p>
            </div>
        </div>
    </div>
</section>
<!-- Early Error Indicator -->

<!-- Valuable Insights -->
<section class="blockListing lightGray">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6">
                <div class="row mx-0 justify-content-between">
                    <h4>Valuable Insights</h4>
                </div>
                <p class="remove-margin-line-height">
                    <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> insights make your automation life easier. We help customers improve their processes by providing historical data analysis on automation, correlation of events on integration points and error scenarios. We work with our customers to make a joint roadmap on integration and automation that helps deliver value to the end users. Our customers also enjoy the support of the platform with their proof of concepts and co-innovation projects.
                </p>
            </div>
            <div class="col-md-12 col-lg-6 text-right">
                <img style="pointer-events: none;"  src="assets/images/icons/company/valuable_insights.svg" alt="Valuable Insights" oncontextmenu="return false;">
            </div>
        </div>
    </div>
</section>
<!-- Valuable Insights -->

<!-- All-in-ONE Subscription -->
<section class="blockListing">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6">
                <img style="pointer-events: none;"  src="assets/images/icons/company/all_in_one_subscription.svg" alt="All-in-ONE Subscription" oncontextmenu="return false;">
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="row mx-0 justify-content-between">
                    <h4>All-in-ONE Subscription</h4>
                </div>
                <p class="remove-margin-line-height">
                    One simple subscription fee covers all automation scenarios. From ideation to concept, solution, implementation, upgrades, maintenance, monitoring, analysis and support, all steps of your automation projects are covered in our fully managed automation model.
                </p>
            </div>
        </div>
    </div>
</section>
<!-- All-in-ONE Subscription -->

<!-- Partners -->
<app-partners></app-partners>
<!-- Partners -->

<!-- End-Users -->
<app-endusers></app-endusers>
<!-- End-Users -->

<!-- Awards -->
<app-awards></app-awards>
<!-- Awards -->

<!-- Our Difference -->
<section class="sectionDifference section-our-difference">
    <div class="container text-center">
        <div class="card-box-alternative our-difference">
            <div class="row">
                <div class="col-md-12">
                    <h2>Experience Our Difference</h2>
                    <p class="mb-0 contentArea remove-margin-line-height">
                        Your organization might rely on different business application, be it legacy or modern- <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> provides a solution that will help integrate your critical business application. Save your business time and money!
                    </p>
                    <p></p>
                    <button type="button" class="btn btn-primary rounded-0" (click)="toggleModal()">Schedule a Demo</button>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Our Difference -->