<section class="awardListing" id="awards">
    <div class="container">

            <div class="row d-flex justify-content-center">   
                <div class="col-6 col-sm-4 col-lg-3 mb-4 align-self-center" *ngFor="let award of items">
                    <div *ngIf="award.link==null?false:true">
                        <a [href]="award.link">
                            <div class="row mx-0 card  align-items-center">
                                <img style="pointer-events: none;"  *ngIf="award.logo"
                                            src="{{apiURL}}/attachments/{{award.logo}}"
                                            alt="{{award.title}}">
                            </div>
                        </a>
                    </div>
                    <div *ngIf="award.link==null?true:false">
                        <div class="row mx-0 card  align-items-center">
                            <img style="pointer-events: none;"  *ngIf="award.logo"
                                        src="{{apiURL}}/attachments/{{award.logo}}"
                                        alt="{{award.title}}">
                        </div>
                    </div>
                </div>
            </div>
                
            <div class="row d-flex justify-content-center">    
                <div class="col-4 mt-4 text-center">
                    <a class="cercle_arrow" *ngIf="has_next" (click)="loadMore()" style="cursor: pointer;">
                        <svg width="32" height="32" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 44C35.046 44 44 35.046 44 24C44 12.954 35.046 4 24 4C12.954 4 4 12.954 4 24C4 35.046 12.954 44 24 44Z" fill="white" stroke="#C0C0C0" stroke-width="3" stroke-linejoin="round"/>
                            <path d="M33 21L24 30L15 21" stroke="#C0C0C0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>
                    <a class="cercle_arrow" *ngIf="!has_next && page!=1" (click)="showLess()" style="cursor: pointer;">
                        <svg style="transform:rotate(180deg);" width="32" height="32" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24 44C35.046 44 44 35.046 44 24C44 12.954 35.046 4 24 4C12.954 4 4 12.954 4 24C4 35.046 12.954 44 24 44Z" fill="white" stroke="#C0C0C0" stroke-width="3" stroke-linejoin="round"/>
                                <path d="M33 21L24 30L15 21" stroke="#C0C0C0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>
                </div>
            </div>
        
    </div>
</section>
