import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiAutomationScenarioService } from '../services/api-automation-scenario.service'
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { Tilebackground } from '../tilebackground';
import { Subscription, interval } from 'rxjs';
import { runmybot_registered_html_replace } from '../runmybot-replacer';
import { runmybot_registered_unicode_replace } from '../runmybot-replacer';

@Component({
  selector: 'app-automation-scenario-sap',
  templateUrl: './automation-scenario-sap.component.html',
  styleUrls: ['./automation-scenario-sap.component.scss']
})
export class AutomationScenarioSapComponent implements OnInit {
  runmybot_registered_html_replace=runmybot_registered_html_replace;
  runmybot_registered_unicode_replace=runmybot_registered_unicode_replace;
  
  automationScenarios: any = [];
  enterprise_filter_list: any = [];
  erp_filter_list: any = [];
  automationSubscribe: any;
  search_function: any = "";
  search_application: any = "";
  search_keyword: any = "";
  automationFilterSubscribe: any;
  query: any = {};
  apiURL: any = environment.apiURL
  erp_name: any = "";
  page: number = 1;
  has_next: number = 0;
  ids: any = [];
  isDataLoaded: string = 'no';

  sap_application_start_string: string = 'sap';
  intervalSubscription: Subscription = new Subscription; 
  source = interval(2000);
  tilebackgrounds:Tilebackground[]=[];
  lastHighlightTileId: string ="";
  lastHighlightTileBackground: string ="";
  lastHighlightTile: Tilebackground | undefined;
  bg_sap: string ="#F7F7F7";
  bg_nonsap: string ="#FFFFFF";
  highlight_sap: string ="#ABE2ABA3";
  highlight_nonsap: string ="#A8BEDCA3";
  randomTileBgToggle:boolean = true;
  

  constructor(private apiAutomationScenarioService: ApiAutomationScenarioService, private route: ActivatedRoute, private title: Title, private router: Router) {
    if(!localStorage.getItem('page_session')){
      localStorage.setItem('page_session','d');
    }
    router.events.subscribe((event:any) => {
      if (event instanceof NavigationEnd) {
        if(localStorage.getItem('page_session')=="n"){
          localStorage.setItem('automation_sap_page','1');
        }
        this.loadAutomationScenarioList();
      }
    });
    
  }

  ngOnInit(): void {
    this.query = {};
    localStorage.setItem('automation_sap_page','1');
    if(this.route.snapshot.params.slug){
      this.search_application = this.route.snapshot.params.slug;
    }
    // console.log("params['query']");
    this.route.queryParams.subscribe(params => {
      if(params['query']){
        // console.log(params['query']);
        this.search_function = parseInt(params['query']);
        this.functionFilter();
      }
    });

    this.loadAutomationFilterList();
    this.loadAutomationScenarioList();

    //random highlight
    this.intervalSubscription = this.source.subscribe(val => this.randomHighlight()); 
  }

  /**
   * Load Automation Scenario List
   */
  loadAutomationScenarioList() {
    this.query['erp'] = 'sap';
    this.query['erp_slug'] = this.search_application;
    var pg = localStorage.getItem('automation_sap_page');
    if(pg && pg!=null){
      this.page = parseInt(pg);
    }
    this.query['page'] = this.page;
    this.query['page_session'] = localStorage.getItem('page_session');
    this.automationSubscribe = this.apiAutomationScenarioService.load_automation_list(this.query).subscribe((data: any) => {
      if (data.success) {
        this.erp_name = data.erp;
        if(this.route.snapshot.params.slug){
          this.title.setTitle(`Enterprise | ${this.erp_name}`);
        }else{
          this.title.setTitle(`Automation Library`);
        }
        this.ids = new Set(this.automationScenarios.map((d:any) => d.id));
        this.automationScenarios = [...this.automationScenarios, ...data.automation_scenario_list.filter((d:any) => !this.ids.has(d.id))];

        this.page = data.page;
        this.has_next = data.has_next;
        setTimeout(()=>{
          this.isDataLoaded = 'yes';
          // localStorage.setItem('page_session','n');
        },200);
        this.setAutomationScenarioListHighlightColour();
        window.scroll(0,window.scrollY+1);
        window.scroll(0,window.scrollY-1);
      } else {
        console.log(data.msg);
        setTimeout(()=>{
          this.isDataLoaded = 'yes';
        },200);
      }
    }, (error: any) => {
      console.log(error);
      setTimeout(()=>{
        this.isDataLoaded = 'yes';
      },200);
    });
    
  }

  /**
   * Load Automation Filters
   */
  loadAutomationFilterList() {
    var fltr = {'erp':'sap'};
    this.automationFilterSubscribe = this.apiAutomationScenarioService.load_filter_list(fltr).subscribe((data: any) => {
      if (data.success) {
        this.enterprise_filter_list = data.enterprise_list;
        this.erp_filter_list = data.erp_list;
      } else {
        console.log(data.msg);
      }
    }, (error: any) => {
      console.log(error);
    });
  }

  /**
   * Function change filter
   */
  functionFilter() {
    this.isDataLoaded = 'no';
    this.automationScenarios = [];
    this.page = 1;
    localStorage.setItem('automation_sap_page',this.page.toString());
    localStorage.setItem('page_session','n');
    this.query['function'] = this.search_function;
    this.loadAutomationScenarioList();
  }

  /**
   * Application change filter
   */
  applicationFilter() {
    this.isDataLoaded = 'no';
    this.automationScenarios = [];
    this.page = 1;
    localStorage.setItem('automation_sap_page',this.page.toString());
    localStorage.setItem('page_session','n');
    this.loadAutomationScenarioList();
  }

  /**
   * Keyword Search
   */
  keyword_search() {
    this.isDataLoaded = 'no';
    this.automationScenarios = [];
    this.page = 1;
    localStorage.setItem('automation_sap_page',this.page.toString());
    localStorage.setItem('page_session','n');
    this.query['search_keyword'] = this.search_keyword;
    this.loadAutomationScenarioList();
  }

  /**
   * Load more data
   */
  loadMore() {
    if (this.has_next) {
      this.page++;
      localStorage.setItem('automation_sap_page',this.page.toString());
      this.loadAutomationScenarioList();
    }
  }

  scrollTo(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

  // loadTitle(title:any){
  //   console.log(title.length);
  //   if (title.length <= 30){
  //     return `${title}`;
  //   } else {
  //     return title;
  //   }
  // }

  /**
   * Destroy
   */
  ngOnDestroy() {
    if (this.automationSubscribe) this.automationSubscribe.unsubscribe();
    this.intervalSubscription.unsubscribe();
  }

  //marks the tiles in view port
  onElementView(value: any, tileId: any, tilebackground: Tilebackground): void {
    for (var index in this.tilebackgrounds) {
      if(this.tilebackgrounds[index].tileId==tileId){
        if(value){
        this.tilebackgrounds[index].active=true;
        }
        else{
          this.tilebackgrounds[index].active=false;
        }
      }
    }
  }

  //making a collection of all tiles with their background and a marker for active tiles
  setAutomationScenarioListHighlightColour(){
    console.log('automationScenarios',this.automationScenarios) ;
    this.tilebackgrounds=[];
      for (let automationScenario of this.automationScenarios) {
         const isSapTileLocal = automationScenario.application__slug.toLowerCase().includes(this.sap_application_start_string.toLowerCase());
        this.tilebackgrounds=[...this.tilebackgrounds,
          {
            tileId: 'tile'+automationScenario.id,
            active: false,
            background: isSapTileLocal?this.bg_sap:this.bg_nonsap,
            isSapTile: isSapTileLocal
          }];
      }
      console.log('tilebackgrounds',this.tilebackgrounds) ;
      this.randomHighlight();
  }

  //random highlight
  randomHighlight(){
      const inViewTiles: Tilebackground[] = [];
      for (var index in this.tilebackgrounds) {
        if(this.lastHighlightTileId==this.tilebackgrounds[index].tileId){
          this.tilebackgrounds[index].background = this.lastHighlightTileBackground;
          continue;
        }
        if(this.tilebackgrounds[index].active){
          inViewTiles.push(this.tilebackgrounds[index]);
        }
      }
    if(inViewTiles.length!=0){  
      if(this.randomTileBgToggle){
          const tile: Tilebackground = inViewTiles[Math.floor(Math.random()*inViewTiles.length)];
          this.lastHighlightTileBackground = tile.background;
          this.lastHighlightTileId=tile.tileId;
          if(tile.isSapTile){
            tile.background=this.highlight_sap;
          }else{
            tile.background=this.highlight_nonsap;
          }
          this.lastHighlightTile=tile;
      }else{
        if(this.lastHighlightTile!= undefined){
          this.lastHighlightTile.background = this.lastHighlightTileBackground;
        }
      }
      this.randomTileBgToggle=!this.randomTileBgToggle;
    }
  }
}
