import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component'
import { HomeComponent } from './home/home.component';
import { EnterpriseComponent } from './enterprise/enterprise.component';
import { AboutComponent } from './about/about.component';
import { AutomationLibraryComponent } from './automation-library/automation-library.component';
import { CoeComponent } from './coe/coe.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { IntegrationServicesComponent } from './integration-services/integration-services.component';
import { AutomationScenarioComponent } from './automation-scenario/automation-scenario.component';
import { AutomationScenarioSapComponent } from './automation-scenario-sap/automation-scenario-sap.component';
import { AutomationScenarioDetailsComponent } from './automation-scenario-details/automation-scenario-details.component';
import { StreamingAiComponent } from './streaming-ai/streaming-ai.component';
import { CpoComponent } from './cpo/cpo.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { CareerComponent } from './career/career.component';
import { RpageComponent } from './rpage/rpage.component';
import { HyperautomationAsAServiceComponent } from './hyperautomation-as-a-service/hyperautomation-as-a-service.component';
import { HyperautomationInSapComponent } from './hyperautomation-in-sap/hyperautomation-in-sap.component';

const routes: Routes = [
  {
    path: '', component: LayoutComponent, children: [
      { path: '', component: HomeComponent },
      { path: 'enterprise-application', component: EnterpriseComponent },
      { path: 'enterprise-application/:slug', component: AutomationScenarioComponent },
      { path: 'automation-library/:slug/:automation_slug', component: AutomationScenarioDetailsComponent },
      { path: 'company', component: AboutComponent },
      { path: 'automation-library', component: AutomationScenarioComponent },
      { path: 'sap', component: AutomationScenarioSapComponent },
      { path: 'automation-coe', component: CoeComponent },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'bpo-business-consulting', component: IntegrationServicesComponent },
      { path: 'ai-streaming', component: StreamingAiComponent },
      { path: 'chief-product-officer', component: CpoComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'terms-of-use', component: TermsOfUseComponent },
      { path: 'careers', component: CareerComponent },
      { path: 'career', redirectTo:'careers' },
      { path: 'resources/runmybot-for-sap/solution-brief', component: HyperautomationAsAServiceComponent },
      { path: 'resources/runmybot-for-sap/one-pager', component: HyperautomationInSapComponent },
      { path: 'automation-library/sap-s4-hana/vendor-invoice-posting/rpage', component: RpageComponent },
      { path: '**', pathMatch: 'full', component: PagenotfoundComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
