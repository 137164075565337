import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service'
import { Title, Meta } from '@angular/platform-browser'

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private commonService: CommonService, private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle("Company | RUNMYBOT delivers digitally connected truly automated business process | actionfi");
    this.meta.updateTag({ 
      name: 'description',
      content: 'actionfi is the first company of its kind to custom create an integrated Bot as a service platform'
    });
  }

  toggleModal() {
    this.commonService.showGetStartedModal("toggle");
  }

}
