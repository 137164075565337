<!--Section Banner-->
<section class="enterpriseBannerWidget">
    <div class="container">
        <div class="row ">
            <div class="col-md-12 col-lg-10 mb-5">
                <h1>Business-Specific Built-In Integrations to ERP Applications & Systems</h1>
                <p>
                    <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> has built-in, function-and-technology-specific integrations that meet exact business needs and automation scenarios and supports multiple versions and a range of APIs and integration standards.
                </p>
                <button type="button" class="btn btn-primary rounded-0" (click)="toggleModal()">Schedule a Demo</button>
            </div>
        </div>
    </div>

</section>
<!--Section Banner End-->
<!--Section two Start-->
<section class="pageEnterpriseSectionTwo">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-4 mb-5">
                <h4>SMART Integration</h4>
                <p>
                    Each integration is designed for specific automation scenarios, utilizing a set of APIs and integration points provided by the systems and application vendors. Chatbots and RPA-style automation are used where applicable.
                </p>
            </div>
            <div class="col-md-12 col-lg-4 mb-5">
                <h4>Fully Managed</h4>
                <p>All integrations are fully managed and are part of the end-to-end automation designed for your specific business scenario. These integrations are always upgraded to the newest version as available, and are monitored and supported 24/7.</p>
            </div>
            <div class="col-md-12 col-lg-4 mb-5">
                <h4>Secure & Scalable</h4>
                <p>
                    <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> uses standard vendor-recommended integrations and APIs. Also, the automation design considers the customer’s enterprise standards for security and access protocols. Scaling and updating those technology stacks are part of the managed offering.
                </p>
            </div>
        </div>
    </div>
</section>
<!--Section two End-->
<!--Connect with : Start-->
<section class="pageEnterpriseSectionThree">
    <div class="container">
        <div class="row ">
            <div class="col-md-12 col-lg-12">
                <h2>Connect with <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> and see powerful results </h2>
            </div>
            <div class="col-md-6 col-lg-4 my-3" *ngFor="let enterprise of enterprises">
                <a [routerLink]="['/enterprise-application',enterprise.slug]">
                    <div class=" row mx-0 card justify-content-between align-items-center Box-tile">
                        <div class="col-md-auto px-0">
                            <img style="pointer-events: none;"  src="{{apiURL}}/attachments/{{enterprise.logo}}" alt="{{enterprise.title}}">
                        </div>
                        <div class="col-md-auto px-0">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M16 31V31C7.715 31 1 24.285 1 16V16C1 7.715 7.715 1 16 1V1C24.285 1 31 7.715 31 16V16C31 24.285 24.285 31 16 31Z"
                                    stroke="#7398C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22.6663 15.9998H9.33301" stroke="#7398C7" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.667 11L22.667 16L17.667 21" stroke="#7398C7" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
<!--Connect with : End-->