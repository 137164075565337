import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiAutomationScenarioService } from '../services/api-automation-scenario.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy {

  contactForm: any;
  isFormSubmitted: boolean = false;
  contactFormSubscribe: any;
  success_msg: any = null;
  error_msg: any = null;
  recaptchaKey: any = environment.recaptchaKey;

  @ViewChild('captchaElem') captchaElem: any;
  // @ViewChild('langInput') langInput: ElementRef;

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme:any= 'light';
  public size:any= 'normal';
  public lang = 'en';
  public type:any= 'image';

  constructor(private title: Title, private formBuilder: UntypedFormBuilder, private apiAutomationScenarioService: ApiAutomationScenarioService, private meta: Meta) { 
    this.captchaElem = ReCaptcha2Component;
  }

  ngOnInit(): void {
    this.title.setTitle("Contact actionfi | Register for RUNMYBOT Demo");
    this.meta.updateTag({ 
      name: 'description',
      content: 'Get a sneak preview of how RUNMYBOT can transform your business processes by scheduling  personalized demo.'
    });
    this.buildForm();
  }

  /**
   * Get form controls
   */
  get formControls() {
    return this.contactForm.controls;
  }

  /**
   * Build form
   */
  buildForm() {
    this.contactForm = this.formBuilder.group({
      full_name: [null, Validators.required],
      email: [null, [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      message: [null],
      recaptcha: ['', Validators.required]
    });
  }

  /**
   * Save Demo Form
   */
   contactFormSubmit(){
    this.isFormSubmitted = true;
    if (this.contactForm.invalid) {
      return;
    }
    let query = {
      full_name: this.formControls.full_name.value,
      email: this.formControls.email.value,
      message: this.formControls.message.value,
    }
    this.contactFormSubscribe = this.apiAutomationScenarioService.save_contact_request(query).subscribe((data: any) => {
      this.success_msg = data.msg;
      this.isFormSubmitted = false;
      this.captchaElem.resetCaptcha();
      this.contactForm.reset();
      setTimeout(()=>{
        this.success_msg = null;
      },2000);
      console.log(data);
    }, (error: any) => {
      this.error_msg = error.error.msg;
      console.log(error);
      setTimeout(()=>{
        this.error_msg = null;
      },2000);
    });
  }

  handleSuccess(data:any) {
    console.log(data);
  }

  ngOnDestroy(){
    if(this.contactFormSubscribe){
      this.contactFormSubscribe.unsubscribe();
    }
    // if(this.captchaElem){
    //   this.captchaElem.resetCaptcha();
    // }
  }

}
