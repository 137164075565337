import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service'
import { Title, Meta } from '@angular/platform-browser'
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-cpo',
  templateUrl: './cpo.component.html',
  styleUrls: ['./cpo.component.scss']
})
export class CpoComponent implements OnInit {

  constructor(private commonService: CommonService, private title: Title, private meta: Meta, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.title.setTitle("Chief Product Officer | Automate business processes on premise or in the cloud using RUNMYBOT | actionfi");
    this.meta.updateTag({
      name: 'description',
      content: 'Fully managed integration of your product with customer systems, on-premise or on the cloud'
    });
    this.route.queryParams.subscribe(params => {
      if (params.demo == "y") {
        setTimeout(()=>{
          this.toggleModal();
        },100);
      }
    });
  }

  toggleModal() {
    this.commonService.showGetStartedModal("toggle");
  }

}
