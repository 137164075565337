<section class="blockListing">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6">
                <div class="row mx-0 justify-content-between">
                    <!-- <h1>404</h1> -->
                </div>
            </div>
            <div class="col-md-12 col-lg-12 text-center">
                <img style="pointer-events: none;"  src="assets/images/404.svg" alt="Page Not Found">
            </div>
            <div class="col-md-12 col-lg-12 text-center">
                <button type="button" class="btn btn-primary rounded-0" (click)="backHome()">Back to Home</button>
            </div>
        </div>
    </div>
</section>