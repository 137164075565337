
<!--Section two Start-->
<section class="sapSectionTwo automation-lib-media">
    <div class="container">
        <div class="row ">
            <div class="col-md-12 col-lg-12 mb-4">
                <h2>Connect with <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> and see powerful results</h2>
            </div>
            <div class="col-md-4 col-lg-4 mb-4">
                <div class="form-group">
                    <select class="form-control" name="search_function" id="search_function"
                        [(ngModel)]="search_function" (change)="functionFilter()">
                        <option value="">By Function</option>
                        <option *ngFor="let erp_filter of erp_filter_list" value="{{erp_filter.id}}">
                            {{erp_filter.title}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 mb-4">
                <div class="form-group">
                    <select class="form-control" name="search_application" id="search_application"
                        [(ngModel)]="search_application" (change)="applicationFilter()">
                        <option value="">By Application</option>
                        <option *ngFor="let enterprise_filter of enterprise_filter_list"
                            value="{{enterprise_filter.slug}}">{{enterprise_filter.title}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3 col-lg-4 mb-4">
                <div class="form-group">
                    <input class="form-control" name="search_keyword" id="search_keyword" placeholder="Search"
                        [(ngModel)]="search_keyword" (keyup)="keyword_search()" autocomplete="off">
                </div>
            </div>
            <div class="col-sm-6 col-md-6 mb-4" *ngIf="!automationScenarios.length && isDataLoaded=='yes'">
                <div class="alert alert-primary" role="alert">
                    No Records Found!
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-4 mb-4 auto-scenario" *ngFor="let automationScenario of automationScenarios;index as i">
                <div class=" row mx-0 card  align-items-center" [style.background]="tilebackgrounds[i].background" (insideViewport)="onElementView($event,'tile'+automationScenario.id,tilebackgrounds[i])">    
                    <img style="pointer-events: none;"  *ngIf="automationScenario.available_sap_store=='Y'" src="assets/images/available_on_sap.png" alt="SAP" style="position: absolute;
                        right: 12px;
                        z-index: 99;
                        width: 5.3rem;
                        top: 12px;">
                    <a [routerLink]="['/automation-library',automationScenario.application__slug,automationScenario.slug]">
                        <div class="col-md-12 px-0 mb-4 custom-mb-4 card-inner">
                            <img style="pointer-events: none;"  *ngIf="automationScenario.icon"
                                src="{{apiURL}}/attachments/{{automationScenario.icon}}"
                                alt="{{runmybot_registered_unicode_replace(automationScenario.title)}}">
                            <img style="pointer-events: none;"  *ngIf="!automationScenario.icon" src="assets/images/cercle-icon.svg"
                                alt="{{runmybot_registered_unicode_replace(automationScenario.title)}}">
                        </div>
                        <div class="col-md-12 px-0">
                            <h4 class="custom_h4 text-center dddd"><div [innerHtml]="runmybot_registered_html_replace(automationScenario.title.slice(0,50)) | sanitizedHtml"></div></h4>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-12 text-right mt-3">
                <a class="cercle_arrow" *ngIf="has_next" (click)="loadMore()">
                    <span class="mr-3">Load more</span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M16 31V31C7.715 31 1 24.285 1 16V16C1 7.715 7.715 1 16 1V1C24.285 1 31 7.715 31 16V16C31 24.285 24.285 31 16 31Z"
                            stroke="#7398C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M22.6663 15.9998H9.33301" stroke="#7398C7" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M17.667 11L22.667 16L17.667 21" stroke="#7398C7" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </a>
            </div>
        </div>
    </div>
</section>
<!--Section two End-->
