<!--Section Banner-->
<section class="sapAutomationBannerWidget">
  <div class="container">
      <div class="row align-items-center">
          <div class="col-md-12 col-lg-6 mb-5">
              <h1><span class="title_run_sm">RUN</span><span class="title_my">MY</span><span class="title_bot">BOT</span><sup><span class="trade-mark">®</span></sup> For SAP</h1>
              <p>
                <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> For SAP is a Hyperautomation-as-a-Service solution built on SAP BTP to help achieve your hyperautomation goals without any back-end customizations in SAP. Now achieve your hyperautomation goals without writing any code.
              </p>
              <button type="button" [routerLink]="['/enterprise-application']" class="btn btn-primary han-blue rounded-0 mr-3">Enterprise Application</button>
              <button type="button" class="btn btn-primary rounded-0" (click)="scrollTo(forSAP)">Automation Library</button>
          </div>
          <!-- [routerLink]="['/automation-library']" -->
          <div class="col-md-12 col-lg-6 pl-5">
              <img style="pointer-events: none;"  src="assets/images/icons/sap/runmybot_for_sap.png" alt="RUNMYBOT For SAP">
          </div>
      </div>
  </div>
</section>
<!--Section Banner End-->

<!--Section block Start-->
<section class="blockListing">
  <div class="container">
      <div class="row align-items-center">
        <div class="col-md-12 col-lg-6 mb-5">
          <img style="pointer-events: none;"  src="assets/images/icons/sap/sap_architecture_diagram.gif" alt="Fully Managed Hyperautomation-as-a-Service (HaaS) Platform Built on SAP BTP">
        </div>
        <div class="col-md-12 col-lg-6 mb-5">
          <div class="row mx-0 justify-content-between">
            <h4>Fully Managed Hyperautomation-as-a-Service (HaaS) Platform Built on SAP BTP</h4>
          </div>
          <p class="remove-margin-line-height">
            Take a different approach to automating Digital Workflows in the modern world that span regions, time zones, departments, systems, technologies and productivity tools with on-premise and on-the-cloud tasks. Achieve faster integrations with functional-specific SAP integrations out of the box, with structured and unstructured data support and AI/ML capabilities.
            <!-- <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> For SAP is a Hyperautomation-as-a-Service solution<br> built on SAP BTP that delivers Reports, Interfaces, Enhancements<br> and Workflows out of the box without back-end customizations in SAP.<br> Now achieve your hyperautomation goals without writing any code. -->
          </p>
        </div>
      </div>
  </div>
</section>
<!--Section block End-->

<!--Section block Start-->
<section class="blockListing lightBlue">
  <div class="container">
      <div class="row align-items-center">
          <div class="col-md-12 col-lg-6 mb-5">
              <div class="row mx-0 justify-content-between">
                <h4>Get Enhancements Delivered on SAP BTP without SAP Customizations</h4>
              </div>
              <p class="remove-margin-line-height">
                Powered by its Event Streaming Choreography Engine, <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> delivers <b><i>Reports</i></b>, <b><i>Interfaces</i></b>, <b><i>Enhancements</i></b> and <b><i>Workflows</i></b> out of the box without any back-end customizations in SAP. Integrate data from non-SAP systems and productivity tools with the help of custom connectors on SAP BTP, and achieve complex, business-specific integrations, data extractions, ETLs and postings in SAP.

                <!-- Using its Event Streaming Choreographic Engine,<br> <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> For SAP helps organizations with faster<br> integration of data, customized reports and<br> business-specific computations without back-end<br> customizations in SAP. AI, ML and NLP capabilities<br> enable <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> to connect decision checkpoints<br> for seamless, end-to-end processes. -->
              </p>
          </div>
          <div class="col-md-12 col-lg-6 mb-5 text-right">
            <div class="iframe-container">
              <iframe width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/o0OBse7UBG0?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>  
            </div> 
          </div> 
      </div>
  </div>
</section>
<!--Section block End-->

<!--Section block Start-->
<section class="blockListing">
  <div class="container">
      <div class="row align-items-center">
          <div class="col-md-12 col-lg-6 mb-5">
              <img style="pointer-events: none;"  src="assets/images/icons/sap/hyperautomation_without_constraints.png" alt="Hyperautomation without constraints">
          </div>
          <div class="col-md-12 col-lg-6 mb-5">
              <div class="row mx-0 justify-content-between">
                <h4>A Fully Managed Platform at a Single Predictable Cost</h4>
              </div>
              <p class="remove-margin-line-height">
                A fully managed, business-specific integration lifecycle from ideation to proof of concept, implementation, maintenance and 24/7 support.
Your end-to-end scenario is Hyperautomated without any costly implementations, enhancements or customizations, and for a single fee. Save on unnecessary license and maintenance costs, training and implementations fees, overhead on statement of work and change requests and thus avoid the costly tech debt you otherwise inherit.
              </p>
          </div>
      </div>
  </div>
</section>
<!--Section block End-->

<section class="blockListingLogo lightBlue">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-6 col-sm-4 col-md-4 col-lg-2"><div class="card lightBlue" ><img style="pointer-events: none;" class="card-img"  src="assets/images/sap/sap.png" alt="SAP"></div></div>
      <div class="col-6 col-sm-4 col-md-4 col-lg-2"><div class="card lightBlue"><img style="pointer-events: none;" class="card-img"  src="assets/images/sap/sap_s4_hana.png" alt="SAP S/4 HANA"></div></div>
      <div class="col-6 col-sm-4 col-md-4 col-lg-2"><div class="card lightBlue"><img style="pointer-events: none;" class="card-img"  src="assets/images/sap/trintech.png" alt="Trintech"></div></div>
      <div class="col-6 col-sm-4 col-md-4 col-lg-2"><div class="card lightBlue"><img style="pointer-events: none;" class="card-img"  src="assets/images/sap/retail_cloud.png" alt="Retail cloud"></div></div>
      <div class="col-6 col-sm-4 col-md-4 col-lg-2"><div class="card lightBlue"><img style="pointer-events: none;" class="card-img" src="assets/images/sap/some_theory.png" alt="Thoth"></div></div>
      <div class="col-6 col-sm-4 col-md-4 col-lg-2"><div class="card lightBlue"><img style="pointer-events: none;" class="card-img" src="assets/images/sap/dashjoin.png" alt="Dashjoin"></div></div>
      <div class="col-6 col-sm-4 col-md-4 col-lg-2"><div class="card lightBlue" style="padding-left: 10px;padding-right: 10px;"><img style="pointer-events: none;" class="card-img" src="assets/images/sap/optimus_price.png" alt="Optimus Price"></div></div>
      <div class="col-6 col-sm-4 col-md-4 col-lg-2"><div class="card lightBlue"><img style="pointer-events: none;" class="card-img" src="assets/images/sap/uxp.png" alt="UXP"></div></div>
    </div>
  </div>
</section>

<!--Section two Start-->
<section class="sapSectionTwo automation-lib-media" #forSAP>
  <div class="container">
      <div class="row ">
          <div class="col-md-12 col-lg-12 mb-4">
              <h2>Feel the Power of <span class="title_run">RUN</span><span class="title_my">MY</span><span class="title_bot">BOT</span><sup><span class="trade-mark">®</span></sup> For SAP</h2>
          </div>
          <div class="col-md-6 col-lg-6 mb-4">
              <div class="form-group">
                  <select class="form-control" name="search_application" id="search_application"
                      [(ngModel)]="search_application" (change)="applicationFilter()">
                      <option value="">By Application</option>
                      <option *ngFor="let enterprise_filter of enterprise_filter_list"
                          value="{{enterprise_filter.slug}}">{{enterprise_filter.title}}</option>
                  </select>
              </div>
          </div>
          <div class="col-md-6 col-lg-6 mb-4">
              <div class="form-group">
                  <input class="form-control" name="search_keyword" id="search_keyword" placeholder="Search"
                      [(ngModel)]="search_keyword" (keyup)="keyword_search()" autocomplete="off">
              </div>
          </div>
          <div class="col-sm-6 col-md-6 mb-4" *ngIf="!automationScenarios.length && isDataLoaded=='yes'">
              <div class="alert alert-primary" role="alert">
                  No Records Found!
              </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-4 mb-4 auto-scenario" *ngFor="let automationScenario of automationScenarios;index as i">
              <div class="row mx-0 card  align-items-center" [style.background]="tilebackgrounds[i].background" (insideViewport)="onElementView($event,'tile'+automationScenario.id,tilebackgrounds[i])">
                <img style="pointer-events: none;"  *ngIf="automationScenario.available_sap_store=='Y'" src="assets/images/available_on_sap.png" alt="SAP" style="position: absolute;
                right: 12px;
                z-index: 99;
                width: 5.3rem;
                top: 12px;">
                  <a [routerLink]="['/automation-library',automationScenario.application__slug,automationScenario.slug]">
                      <div class="col-md-12 px-0 mb-4 custom-mb-4 card-inner">
                          <img style="pointer-events: none;"  *ngIf="automationScenario.icon"
                              src="{{apiURL}}/attachments/{{automationScenario.icon}}"
                              alt="{{runmybot_registered_unicode_replace(automationScenario.title)}}">
                          <img style="pointer-events: none;"  *ngIf="!automationScenario.icon" src="assets/images/cercle-icon.svg"
                              alt="{{runmybot_registered_unicode_replace(automationScenario.title)}}">
                      </div>
                      <div class="col-md-12 px-0">
                          <h4 class="custom_h4 text-center dddd"><div [innerHtml]="runmybot_registered_html_replace(automationScenario.title.slice(0,50)) | sanitizedHtml"></div></h4>
                      </div>
                  </a>
              </div>
          </div>

          <div class="col-12 text-right mt-3">
              <a class="cercle_arrow" *ngIf="has_next" (click)="loadMore()">
                  <span class="mr-3">Load more</span>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M16 31V31C7.715 31 1 24.285 1 16V16C1 7.715 7.715 1 16 1V1C24.285 1 31 7.715 31 16V16C31 24.285 24.285 31 16 31Z"
                          stroke="#7398C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M22.6663 15.9998H9.33301" stroke="#7398C7" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                      <path d="M17.667 11L22.667 16L17.667 21" stroke="#7398C7" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
              </a>
          </div>
      </div>
  </div>
</section>
<!--Section two End-->
