import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service'
import { Title, Meta } from '@angular/platform-browser'

@Component({
  selector: 'app-streaming-ai',
  templateUrl: './streaming-ai.component.html',
  styleUrls: ['./streaming-ai.component.scss']
})
export class StreamingAiComponent implements OnInit {

  constructor(private commonService: CommonService, private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle("Streaming AI | AI powered stream processing to manage your IoT data needs | actionfi");
    this.meta.updateTag({ 
      name: 'description',
      content: 'The AI powered platform can process your data in motion from IoT devises to standard systems and IT workloads asynchronously to complete a business process integration.'
    });
  }

  toggleModal() {
    this.commonService.showGetStartedModal("toggle");
  }

}
