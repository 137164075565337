<!--Section block Start-->

<section class="sapDetailPage">
    <div class="container">
        <div class="row">
            <div class="col-12 mb-3">
                <a [routerLink]="['/automation-library']" class="btn-link btn-back">
                    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.66634 7.99935L20.333 7.99935" stroke="#7398C7" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8.33301 1.33333L1.66634 8" stroke="#7398C7" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M8.33301 14.666L1.66634 7.99935" stroke="#7398C7" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>Back To Automation Library
                </a>
            </div>
        </div>
    </div>
</section>

<section class="sapDetailPage custom-title-container">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2><div [innerHtml]="runmybot_title_registered_html_replace(shortDescription) | sanitizedHtml"></div></h2>
            </div>
        </div>
    </div>
</section>

<section *ngFor="let automationScenario of automationScenarios" class="automationScenarioDetailPage custom-content-container">
    <div class="container">
        <div class="row" [ngClass]="{'align-items-center': automationScenario.image_positions == 'left' || automationScenario.image_positions == 'right' }">
            <div class="col-12" *ngIf="automationScenario.image_positions == null">
                <!-- <h2>{{automationScenario.title}}</h2> -->
                <div class=" content-container" [innerHtml]="runmybot_registered_html_replace(automationScenario.description) | sanitizedHtml"></div>
            </div>
            <div class="col-md-12 col-lg-6 mb-5 problmImg" *ngIf="automationScenario.image_positions == 'left'">
                <img style="pointer-events: none;"  src="{{apiURL}}/attachments/{{automationScenario.icon}}" alt="{{runmybot_registered_unicode_replace(automationScenario.title)}}">
            </div>
            <div class="col-md-12 col-lg-6 mb-5 pl-80" *ngIf="automationScenario.image_positions == 'left'">
                <!-- <h4>{{automationScenario.title}}</h4> -->
                <div class=" content-container" [innerHtml]="runmybot_registered_html_replace(automationScenario.description) | sanitizedHtml"></div>
            </div>
            <div class="col-md-12 col-lg-6 mb-5 pl-80" *ngIf="automationScenario.image_positions == 'right'">
                <!-- <h4>{{automationScenario.title}}</h4> -->
                <div class=" content-container" [innerHtml]="runmybot_registered_html_replace(automationScenario.description) | sanitizedHtml"></div>
            </div>
            <div class="col-md-12 col-lg-6 mb-5 problmImg" *ngIf="automationScenario.image_positions == 'right'">
                <img style="pointer-events: none;"  src="{{apiURL}}/attachments/{{automationScenario.icon}}" alt="{{runmybot_registered_unicode_replace(automationScenario.title)}}">
            </div>
            <div class="col-md-12 col-lg-12 mb-5 problmImg" *ngIf="automationScenario.image_positions == 'full_width'">
                <!-- <h4>{{automationScenario.title}}</h4> -->
                <img style="pointer-events: none;"  src="{{apiURL}}/attachments/{{automationScenario.icon}}" alt="{{runmybot_registered_unicode_replace(automationScenario.title)}}">
            </div>
            <div class="col-md-12" *ngIf="automation_scenario_services.length">
                <div class="row technologyListing  align-items-center">
                    <ng-container *ngFor="let automation_scenario_service of automation_scenario_services">
                        <div class="col-md-auto pr-80 mb-4" *ngIf="isExist(automationScenario.services, automation_scenario_service.id)">
                            <img style="pointer-events: none;"  class="mr-3" src="{{apiURL}}/attachments/{{automation_scenario_service.icon}}" alt="{{runmybot_registered_unicode_replace(automation_scenario_service.title)}}">
                            <span>{{runmybot_registered_html_replace(automation_scenario_service.title)}}</span>
                        </div>
                    </ng-container>
                </div>
                <hr>
            </div>
        </div>
    </div>
</section>

<section class="sapDetailPage">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <a [routerLink]="['/automation-library']" class="btn-link btn-back btn-back-bottom">
                    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.66634 7.99935L20.333 7.99935" stroke="#7398C7" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8.33301 1.33333L1.66634 8" stroke="#7398C7" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M8.33301 14.666L1.66634 7.99935" stroke="#7398C7" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>Back To Automation Library
                </a>
            </div>
        </div>
    </div>
</section>

<!--Call to action start-->
<section class="demoSection">
    <div class="container text-center">
        <h2>Schedule a Demo</h2>
        <p>Schedule a live demo with one of our product specialist at your convenience. We can learn about your
            requirements, answer questions and review so that we can help your organization</p>
        <button type="button" class="btn btn-primary rounded-0" (click)="toggleModal()">Schedule a Demo</button>
    </div>
</section>
<!--Call to action end -->
