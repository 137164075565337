<section class="partnerListing lightGray" id="partners">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <h2>Partners</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-6 col-sm-4 col-lg-3 mb-4" *ngFor="let partner of items">
        <div class="card">
          <img
            style="pointer-events: none"
            class="card-img"
            *ngIf="partner.logo"
            src="{{ apiURL }}/attachments/{{ partner.logo }}"
            alt="{{ partner.title }}"
          />
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-center">
      <div class="col-4 mt-4 section-edge text-center">
        <a
          class="cercle_arrow"
          *ngIf="has_next"
          (click)="loadMore()"
          style="cursor: pointer"
        >
        <svg width="32" height="32" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50" fill="rgb(115, 153, 198)" />
          <path d="M30 40 L50 60 L70 40" stroke="#FFFFFF" stroke-width="10" fill="none" />
        </svg>
          
          
        </a>
        <a
          class="cercle_arrow"
          *ngIf="!has_next && page != 1"
          (click)="showLess()"
          style="cursor: pointer"
        >
        <svg width="32" height="32" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50" fill="rgb(115, 153, 198)" />
          <path d="M30 60 L50 40 L70 60" stroke="#FFFFFF" stroke-width="10" fill="none" />
        </svg>
        </a>
      </div>
    </div>
  </div>
</section>
