import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service'
import { Title, Meta } from '@angular/platform-browser'

@Component({
  selector: 'app-coe',
  templateUrl: './coe.component.html',
  styleUrls: ['./coe.component.scss']
})
export class CoeComponent implements OnInit {

  constructor(private commonService: CommonService, private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle("Automation COE | Managed Automation at Predictable Cost | actionfi");
    this.meta.updateTag({ 
      name: 'description',
      content: 'Function specific, end-to-end automation delivered and managed on a fixed pricing model'
    });
  }

  toggleModal() {
    this.commonService.showGetStartedModal("toggle");
  }

}
