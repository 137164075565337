<!--Section Banner-->
<section class="cpoBannerWidget">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6 mb-5">
                <h1>Customer-First Approach</h1>
                <p>Solve integration anxiety with scalable, purpose-built and fully managed integrations with <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup>. Ensure great experience for your customers at every stage of your journey with them. Fully Managed Integration Lifecycle.</p>
                <button type="button" class="btn btn-primary han-blue rounded-0 mr-3"
                    [routerLink]="['/enterprise-application']">Enterprise Applications</button>
                <button type="button" class="btn btn-primary rounded-0" (click)="toggleModal()">Schedule a Demo</button>
            </div>
            <div class="col-md-12 col-lg-6 pl-5">
                <img style="pointer-events: none;"  src="assets/images/cpo_banner_Illu.svg" alt="image-1">
            </div>
        </div>
    </div>

</section>
<!--Section Banner End-->
<!--Section block Start-->
<section class="blockListing">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6 mb-5">
                <img style="pointer-events: none;"  src="assets/images/icons/cpo/fully_managed_integration_lifecycle.svg" alt="tactical">
            </div>
            <div class="col-md-12 col-lg-6 mb-5">
                <div class="row mx-0 justify-content-between">
                    <h4>Fully Managed Integration lifecycle</h4>
                </div>
                <p class="remove-margin-line-height">
                    With <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup>, you gain fully managed integration of your product with customer systems, on-premise or on the cloud. With evolving customer expectations, providing excellent digital product experience to them requires native, customized integration with their systems. Just an API or externally accessible integration point can’t satisfy customers’ expectation for a complete, end-to-end integration from their vendor of choice. With actionfi’s <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> platform, you achieve just this: seamless integration that is purpose-built and fully managed.
                </p>
            </div>
        </div>
    </div>
</section>
<!--Section block End-->
<!--Section block Start-->
<section class="blockListing lightGray">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6 mb-5">
                <div class="row mx-0 justify-content-between">
                    <h4>Prioritise Your Product Roadmap and Innovation</h4>
                </div>
                <p class="remove-margin-line-height">
                    With actionfi as a partner, you can focus on product innovation and invest in your vision. Our AI-driven automation platform helps scale your purpose-built integration to meet new milestones. It also allows enhancements and ensures support with upcoming updates, thus freeing up more of your time and resources to focus on products and technology and ensuring great product experience for your customers. <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> ensures last mile automation and also scales and syncs with upgrades to your systems and ERP version. With <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup>, integrations are just a part of your fully managed lifecycle, delivered at simple and predictable cost.
                </p>
            </div>
            <div class="col-md-12 col-lg-6 mb-5 text-right">
                <img style="pointer-events: none;"  src="assets/images/icons/cpo/prioritise_your_product_roadmap_and_innovation.svg" alt="zerolineOfCode">
            </div>

        </div>
    </div>
</section>
<!--Section block End-->
<!--Section block Start-->
<section class="blockListing">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6 mb-5">
                <img style="pointer-events: none;"  src="assets/images/icons/cpo/gain_competitive_advantage_address_larger_market.svg" alt="innovatively">
            </div>
            <div class="col-md-12 col-lg-6 mb-5">
                <div class="row mx-0 justify-content-between">
                    <h4>Gain Competitive Advantage, Address Larger Market </h4>
                </div>
                <p class="remove-margin-line-height">
                    <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> helps connect your integration and technology capabilities with your go-to market channels to gain instant competitive advantage. Its library of automation and integration capabilities helps you succeed in more competitive markets. Your customers expect you to offer functional knowledge about their different systems, business-specific outcomes and the technology to deliver that end-to-end business process. actionfi brings this functional and business expertise on various ERP systems. <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> subscription provides you instant access to this library that helps you evaluate customer scenarios and provide custom-designed demos that address their unique requirements. Together, we deliver proof of concepts and final implementation.
                </p>
            </div>
        </div>
    </div>
</section>
<!--Section block End-->



<!--Section demoSection start-->
<section class="demoSection">
    <div class="container text-center">
        <h2>Start Solving Your Most Challenging Integration</h2>
        <p>
            Bring your integration scenario and get a demo on how <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> can help you execute it faster and provide value to your customer. See how it can reduce engineering dependencies in your various initiatives, help you achieve faster innovation cycles and gain flexibility in a tumultuous market to achieve greater speed to market
        </p>
        <button type="button" class="btn btn-primary rounded-0" (click)="toggleModal()">Schedule a Demo</button>
    </div>
</section>