<!--Section Banner-->
<section class="isBannerWidget">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6 mb-5">
                <h1>Sustainable Automation for Transformation Projects</h1>
                <p>Achieve out-of-the-box, pre-integrated, end-to-end automation that enables your digital transformation projects to achieve their goals. Expedite integrations with prebuilt, yet business-specific automation libraries.</p>
                <button type="button" class="btn btn-primary rounded-0" (click)="toggleModal()">Schedule a Demo</button>
            </div>
            <div class="col-md-12 col-lg-6 pl-5">
                <img style="pointer-events: none;"  src="assets/images/business_consulting_banner_Illu.svg" alt="image-1">
            </div>
        </div>
    </div>

</section>
<!--Section Banner End-->
<!--Section block Start-->
<section class="blockListing">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6 mb-5">
                <img style="pointer-events: none;"  src="assets/images/icons/bpo/Sustainable_Automation_in_BPO_Model.svg" alt="tactical">
            </div>
            <div class="col-md-12 col-lg-6 mb-5">
                <div class="row mx-0 justify-content-between">
                    <h4>Sustainable Automation for BPO</h4>
                </div>
                <p class="remove-margin-line-height">
                    <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> brings true end-to-end automation beyond RPA into your BPO model. The platform helps your process become automation-driven and transforms it into an outcome-based business model. Your processes are often more complex than simple, rules-based repetitive tasks and hence require an automation technology capable of business-specific API integrations with legacy systems and complex ERPs. The technology must cope with system changes, ERP version upgrades and changing integration standards at the customer. You will also benefit from an automation platform that delivers insights into automation outcomes for governance and compliance, as well as for continuous improvement. <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> is an AI-driven, fully managed automation platform that delivers the technology layer to achieve end-to-end automation. Analytics that enable continuous process improvement and preventive controls are part of your managed automation implementation.
                </p>
            </div>
        </div>
    </div>
</section>
<!--Section block End-->
<!--Section block Start-->
<section class="blockListing lightGray">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6 mb-5">
                <div class="row mx-0 justify-content-between">
                    <h4>Reduce Implementation Time</h4>
                </div>
                <p class="remove-margin-line-height">
                    <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> helps expedite integrations with prebuilt, yet business-specific automation that mostly address the technical challenges often plaguing project rollouts. The platform’s automation libraries and functional expertise from actionfi help you meet business requirements and keep your project timelines on track. The most detailed technical requirements and specifications are not often clearly defined, nor can they be easily inferred from business requirements. When these additional requirements come up during a project, this often poses a challenge that needs to be addressed quickly and within the scope of the project. With <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup>'s managed automation platform, such changes can be addressed easily and within the scope of the project. With its built-in integrations and the library of automation scenarios, <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> enables faster execution.
                </p>
            </div>
            <div class="col-md-12 col-lg-6 mb-5 text-right">
                <img style="pointer-events: none;"  src="assets/images/icons/bpo/Reduce_implementation_and_time.svg" alt="zerolineOfCode">
            </div>

        </div>
    </div>
</section>
<!--Section block End-->
<!--Section block Start-->
<section class="blockListing">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6 mb-5">
                <img style="pointer-events: none;"  src="assets/images/icons/bpo/COST_OVERRUNNS.svg" alt="innovatively">
            </div>
            <div class="col-md-12 col-lg-6 mb-5">
                <div class="row mx-0 justify-content-between">
                    <h4>No Cost Overruns</h4>
                </div>
                <p class="remove-margin-line-height">
                    With <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup>, you have a fully managed business process automation platform at predictable cost. You save project management time, ensure efficient resource utilization and reduce the risk of unpredictable challenges with the technology and integration stack. The exact details of systems, APIs, interfaces, access methods, internal controls and preferred security standards often emerge in the middle of a project. This often necessitates a change request or even a new SoW. Managing such changes often costs you valuable time and other resources. With <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> Managed Automation, the complete lifecycle of your automation is delivered in one, simple cost model, which includes ideation, design, development, implementation, 24/7 support and maintenance and upgrades. Besides, AI-driven analytics enable continuous process improvements, making your business processes even more efficient.
                </p>
            </div>
        </div>
    </div>
</section>
<!--Section block End-->



<!--Section demoSection start-->
<section class="demoSection">
    <div class="container text-center">
        <h2>Solve Your Automation Challenge at Your Current Project</h2>
        <p>
            Does your transformation project have an integration or automation challenge? See a demo on how <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> can solve those technical challenges and keep your project on track. See how we can help you build a solution plan and even create a proof of concept.
        </p>
        <button type="button" class="btn btn-primary rounded-0" (click)="toggleModal()">Schedule a Demo</button>
    </div>
</section>
