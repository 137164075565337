import { Component, OnInit } from '@angular/core';
import { ApiAutomationScenarioService } from '../services/api-automation-scenario.service'
import { ActivatedRoute } from "@angular/router";
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { runmybot_registered_html_replace } from '../runmybot-replacer';
import { runmybot_registered_unicode_replace } from '../runmybot-replacer';

@Component({
  selector: 'app-automation-library',
  templateUrl: './automation-library.component.html',
  styleUrls: ['./automation-library.component.scss']
})
export class AutomationLibraryComponent implements OnInit {
  runmybot_registered_html_replace=runmybot_registered_html_replace;
  runmybot_registered_unicode_replace=runmybot_registered_unicode_replace;
  
  automationScenarios: any = [];
  enterprise_filter_list: any = [];
  erp_filter_list: any = [];
  automationSubscribe: any;
  search_function: any = "";
  search_application: any = "";
  search_keyword: any = "";
  automationFilterSubscribe: any;
  query: any = {};
  apiURL: any = environment.apiURL
  erp_name: any = "";
  page: number = 1;
  has_next: number = 0;

  constructor(private apiAutomationScenarioService: ApiAutomationScenarioService, private route: ActivatedRoute, private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(`Automation Library | List of automation library | actionfi`);
    this.meta.updateTag({ 
      name: 'description',
      content: 'Browse through list of enterprise applications RUNMYBOT can integrate with. Supports multiple versions and range of APIs and integration standards.'
    });
    this.query = {};
    this.loadAutomationFilterList();
    this.loadAutomationScenarioList();
  }

  /**
   * Load Automation Scenario List
   */
  loadAutomationScenarioList() {
    this.query['erp_slug'] = this.search_application;
    var pg = localStorage.getItem('automation_library_page');
    if(pg && pg!=null){
      this.page = parseInt(pg);
    }
    this.query['page'] = this.page;
    this.automationSubscribe = this.apiAutomationScenarioService.load_automation_list(this.query).subscribe((data: any) => {
      if (data.success) {
        this.erp_name = data.erp;
        this.automationScenarios = this.automationScenarios.concat(data.automation_scenario_list);
        this.page = data.page;
        this.has_next = data.has_next;
      } else {
        console.log(data.msg);
      }
    }, (error: any) => {
      console.log(error);
    });
  }

  /**
   * Load Automation Filters
   */
  loadAutomationFilterList() {
    this.automationFilterSubscribe = this.apiAutomationScenarioService.load_filter_list({}).subscribe((data: any) => {
      if (data.success) {
        this.enterprise_filter_list = data.enterprise_list;
        this.erp_filter_list = data.erp_list;
      } else {
        console.log(data.msg);
      }
    }, (error: any) => {
      console.log(error);
    });
  }

  /**
   * Function change filter
   */
  functionFilter() {
    this.automationScenarios = [];
    this.page = 1;
    this.query['function'] = this.search_function;
    this.loadAutomationScenarioList();
  }

  /**
   * Application change filter
   */
  applicationFilter() {
    this.automationScenarios = [];
    this.page = 1;
    this.loadAutomationScenarioList();
  }

  /**
   * Keyword Search
   */
  keyword_search() {
    this.automationScenarios = [];
    this.page = 1;
    this.query['search_keyword'] = this.search_keyword;
    this.loadAutomationScenarioList();
  }

  /**
   * Load more data
   */
  loadMore() {
    if (this.has_next) {
      this.page++;
      localStorage.setItem('automation_library_page',this.page.toString());
      this.loadAutomationScenarioList();
    }
  }

  /**
   * Destroy
   */
  ngOnDestroy() {
    if (this.automationSubscribe) this.automationSubscribe.unsubscribe();
  }

}
