<footer>
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 mb-4">
                <!-- <p class="contact-p-margin">
                  If there are any questions regarding the privacy policy, you may contact us using the information below.<br>
                </p> -->
                <p>
                  actionfi<br>
                  1441 Broadway,#5<br>
                  New York, NY 10018<br>
                </p>
                <div class="icon_wrap">
                    <img style="pointer-events: none;"  src="assets/images/icons/home/ic_email_wt.svg" alt="mail icon" /><a
                        href="mailto:hello@actionfi.com"> hello@actionfi.com </a><br>
                    <img style="pointer-events: none;"  src="assets/images/icons/home/ic_land-wt.svg" alt="mail icon" /><a href="tel:(646) 290-6311">+1 646 290 6311</a>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 mb-4">
                <p>
                  <br>
                  MEDA Building, Kakkanad,<br>
                  Ernakulam, Kerala, India - 682037<br>
                </p>
                <div class="icon_wrap">
                    <img style="pointer-events: none;"  src="assets/images/icons/home/ic_email_wt.svg" alt="mail icon" /><a
                        href="mailto:hello@actionfi.com"> hello@actionfi.com </a><br>
                    <img style="pointer-events: none;"  src="assets/images/icons/home/ic_land-wt.svg" alt="mail icon"><a href="tel: 91 484 2993399">
                        +91 484 299 3399</a>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 mb-4">
                <p>
                  <br>
                  C5 UL Cyber Park, Special Economic Zone,<br>
                  Calicut, Kerala, India - 673016<br>
                </p>
                <div class="icon_wrap">
                    <img style="pointer-events: none;"  src="assets/images/icons/home/ic_email_wt.svg" alt="mail icon" /><a
                        href="mailto:hello@actionfi.com"> hello@actionfi.com </a><br>
                    <img style="pointer-events: none;"  src="assets/images/icons/home/ic_land-wt.svg" alt="mail icon"><a href="tel:91 495 2973774">+91 495 297 3774</a>
                    <!-- +91 495 235 1100 -->
                </div>

            </div>

            <div class="col-md-12">
                <hr>
            </div>

        </div>
        <div class="row align-items-center">
            <div class="col-md-5">
                <ul class="socialLinks">
                    <li>
                      <a target="_blank" href="https://www.linkedin.com/company/actionfi">
                        <img style="pointer-events: none;"  src="assets/images/icons/social/linkedin.svg" alt="Linkedin">
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/actionfi_" target="_blank">
                        <img style="pointer-events: none;"  src="assets/images/icons/social/x.svg" alt="Twitter">
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/actionfi2015" target="_blank">
                        <img style="pointer-events: none;"  src="assets/images/icons/social/facebook.svg" alt="Facebook">
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.instagram.com/actionfi_/">
                        <img style="pointer-events: none;"  src="assets/images/icons/social/instagram.svg" alt="Instagram">
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.youtube.com/@actionfi">
                        <img style="pointer-events: none;"  src="assets/images/icons/social/youtube.svg" alt="Youtube">
                      </a>
                    </li>
                </ul>
            </div>
            <div class="col-md-7 footer_links">
                <a [routerLink]="['privacy-policy']">Privacy Policy </a><br />
                <a [routerLink]="['terms-of-use']">Terms of Use</a><br />
                <a [routerLink]="['contact-us']">Contact Us</a><br />
                <a [routerLink]="['careers']">Careers</a><br />
                <!-- <p class="copyRight">©{{year}} <a href="#">Actionfi</a> All Rights Reserved. </p> -->
                <p class="copyRight">@Actionable Findings, LLC. All Rights Reserved. </p>
            </div>
            <div class="mobile_copyright">
                <!-- <p class="copyRight">©{{year}} <a href="#">Actionfi</a> All Rights Reserved. </p> -->
                <p class="copyRight">@Actionable Findings, LLC. All Rights Reserved. </p>
            </div>
        </div>
    </div>
</footer>
