import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CareersService } from '../services/careers.service';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {

  page: number = 1;
  has_next: number = 0;
  query: any = {};
  careerSubscribe: any;
  careers: any = [];
  toggleItem: any = "";

  constructor(private careersService:CareersService) { }

  ngOnInit(): void {
    this.loadCareers();
  }

  /**
   * LOAD CAREERS
   */
  loadCareers() {
    this.query['page'] = this.page;
    this.careerSubscribe = this.careersService.load_careers(this.query).subscribe((data: any) => {
      if (data.success) {
        this.careers = this.careers.concat(data.careers);
        this.page = data.page;
        this.has_next = data.has_next;
      }
    }, (error: any) => {
      console.log(error);
    });
  }

  /**
   * TOGGLE READ MORE
   * @param id 
   */
  toggle(id:any) :void{
    if(this.toggleItem!=id) {
      this.toggleItem = id;
    } else {
      this.toggleItem = null;
    }
  }

  /**
   * LOAD MORE
   */
  loadMore() :void {
    if (this.has_next) {
      this.page++;
      this.loadCareers();
    }
  }

}
