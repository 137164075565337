import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CommonService } from '../services/common.service'
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiAutomationScenarioService } from '../services/api-automation-scenario.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { environment } from 'src/environments/environment';
import * as moment from 'moment'
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  showModal: boolean = false;
  demoForm: any;
  isFormSubmitted: boolean = false;
  demoFormSubscribe: any;
  success_msg: any = null;
  error_msg: any = null;
  recaptchaKey: any = environment.recaptchaKey;

  @ViewChild('captchaElemPopup') captchaElemPopup: any;
  // @ViewChild('langInput') langInput: ElementRef;

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme:any= 'light';
  public size:any= 'normal';
  public lang = 'en';
  public type:any= 'image';

  public min_datetime = new Date();
  public default_datetime = new Date();
  intervalSubscription: Subscription = new Subscription; 
  source = interval(1000);

  constructor(private commonService: CommonService, private formBuilder: UntypedFormBuilder, private apiAutomationScenarioService: ApiAutomationScenarioService) {
    this.captchaElemPopup = ReCaptcha2Component;
    commonService.getStartedModal$.subscribe(toggle => {
      this.toggleModal();
    })
  }

  ngOnInit(): void {
    this.buildForm();

    //update lets talk calendar default datetime
    //random highlight
    this.intervalSubscription = this.source.subscribe(val => {
      this.default_datetime = this.addHours(new Date(),1);
    }); 
  }

  /**
   * Get form controls
   */
  get formControls() {
    return this.demoForm.controls;
  }

  addHours (date: Date, hours: number): Date{
    const result = new Date(date);
    result.setHours(result.getHours() + hours);
    return result;
  }

  toggleModal() {
    this.showModal = !this.showModal;

    //set calender minimum time
    this.min_datetime = this.addHours(new Date(),1);
    this.min_datetime.setSeconds(0);
    this.default_datetime = this.addHours(new Date(),1);

    //cleat fields
    this.captchaElemPopup.resetCaptcha();
    this.demoForm.reset();
  }

  /**
   * Build form
   */
   buildForm() {
    this.demoForm = this.formBuilder.group({
      full_name: [null, Validators.required],
      email: [null, [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      date_time: [null,Validators.required],
      message: [null],
      recaptcha: ['', Validators.required]
    });
  }

  /**
   * Save Demo Form
   */
  demoFormSubmit(){
    this.isFormSubmitted = true;
    if (this.demoForm.invalid) {
      return;
    }
    let query = {
      full_name: this.formControls.full_name.value,
      email: this.formControls.email.value,
      date_time: moment(this.formControls.date_time.value).format('YYYY-MM-DD H:m:s'),
      message: this.formControls.message.value,
    }
    this.demoFormSubscribe = this.apiAutomationScenarioService.save_demo_schedule(query).subscribe((data: any) => {
      this.success_msg = data.msg;
      this.isFormSubmitted = false;
      this.captchaElemPopup.resetCaptcha();
      this.demoForm.reset();
      setTimeout(()=>{
        this.success_msg = null;
      },2000);
    }, (error: any) => {
      this.error_msg = error.error.msg;
      setTimeout(()=>{
        this.error_msg = null;
      },2000);
    });
  }

  handleSuccess(data:any) {
    console.log(data);
  }

  ngOnDestroy(){
    if(this.demoFormSubscribe){
      this.demoFormSubscribe.unsubscribe();
    }
  }

}
