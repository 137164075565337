<!--Section Banner-->
<section class="coeBannerWidget">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-9 mb-5">
                <h1>Managed Automation at Predictable Cost</h1>
                <p>
                    Pursue your highest-value opportunities in automation with <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> and get functional specific, end-to-end automation delivered and managed at fixed pricing.
                </p>
                <button type="button" [routerLink]="['/automation-library']"
                    class="btn btn-primary han-blue rounded-0 mr-3">Automation
                    Library</button>
                <button type="button" [routerLink]="['/ai-streaming']" class="btn btn-primary rounded-0">Streaming &
                    AI</button>
            </div> 
            <div class="col-md-12 col-lg-3 pl-5">
                <img style="pointer-events: none;"  src="assets/images/automation_coe_banner_Illu.svg" alt="Managed Automation at Predictable Cost">
            </div>
        </div>
    </div>
</section>
<!--Section Banner End-->
<!--Section block Start-->
<section class="blockListing">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6 mb-5">
                <img style="pointer-events: none;"  src="assets/images/icons/automation_coe/manage_automation_investments_wisely.svg" alt="tactical">
            </div>
            <div class="col-md-12 col-lg-6 mb-5">
                <div class="row mx-0 justify-content-between">
                    <h4>Manage Automation Investments Wisely</h4>
                </div>
                <p class="remove-margin-line-height">
                    The <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> platform delivers your end-to-end, business-specific automation implemented and managed for a fixed price. There are no separate fees for implementation, upgrades, maintenance, support or multiple component licenses. The end-to-end automation is designed, implemented and maintained for one simple subscription fee. When your systems are upgraded or your technology stack changes, the necessary updates to the platform are delivered at no additional fee. With <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup>, you can engage with the line of business with clarity on cost per automation and resource alignment. Because of its built-in integration and automation capabilities, <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> has a shorter implementation cycle time.
                </p>
            </div>
        </div>
    </div>
</section>
<!--Section block End-->
<!--Section block Start-->
<section class="blockListing lightGray">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6 mb-5">
                <div class="row mx-0 justify-content-between">
                    <h4>Automation Beyond RPA</h4>
                </div>
                <p class="remove-margin-line-height">
                    Whereas most RPA solutions don’t scale beyond some quick wins, <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> helps you tackle complex processes besides taking the quick wins of standard RPA implementations. <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> helps address these Day 2 situations when the lines of business come back with more detailed and complex automation requirements. These business requirements often involve unstructured contents, specific validation and orchestration and ERP-specific integration needs.  With <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup>, you can meet the full range of automation requirements from the line of business and provide them a clear scope, resource needs and estimated ROI.
                </p>
            </div>
            <div class="col-md-12 col-lg-6 mb-5 text-right">
                <img style="pointer-events: none;"  src="assets/images/icons/automation_coe/automatio_beyond.svg" alt="zerolineOfCode">
            </div>

        </div>
    </div>
</section>
<!--Section block End-->
<!--Section block Start-->
<section class="blockListing">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-lg-6 mb-5">
                <img style="pointer-events: none;"  src="assets/images/icons/automation_coe/reduce_risk_to_your_enterprise.svg" alt="innovatively">
            </div>
            <div class="col-md-12 col-lg-6 mb-5">
                <div class="row mx-0 justify-content-between">
                    <h4>Reduce Risk to Your Enterprise</h4>
                </div>
                <p class="remove-margin-line-height">
                    <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> simplifies your automation initiatives with a simple, standards-based approach. All actions are auditable, and built-in preventive controls and analysis enable continuous improvement. Your initiatives are no longer burdened by software components with their own licensing terms and costs and requiring special skill sets to build. Nor will you lack visibility into automation results, which slows down opportunities to optimize similar processes and realize a return on investment. Its AI-based analytics provide insights into the components of automation that further help the line of business to optimize the process.
                </p>
            </div>
        </div>
    </div>
</section>
<!--Section block End-->



<!--Section demoSection start-->
<section class="demoSection">
    <div class="container text-center">
        <h2>Solve Your Day 2 Automation Challenge with <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup></h2>
        <p>
            Do you have a complex automation challenge from a line of business that standard RPA cannot meet? Get a demo on how <span class="title_run_sm">RUN</span><span class="title_my_sm">MY</span><span class="title_bot_sm">BOT</span><sup><span class="trade-mark_sm">®</span></sup> can deliver value to your business. See how we can build a use case or proof of concept on end-to-end automation at a predictable cost.
        </p>
        <button type="button" class="btn btn-primary rounded-0" (click)="toggleModal()">Schedule a Demo</button>
    </div>
</section>